//Example
export const USER_TYPES = {
  SUPPERADMIN: "supperAdmin",
  ADMIN: "admin",
  USER: "user",
};

// worksite
export const COLUMNS = {
  PARENT_ACCOUNT: {
    field: "parent_name",
    header: "Parent Account",
    style: { width: "15%" },
    headerClassName: "parent-account-header",
    expander: true,
  },
  // SELECT_ACCOUNT_NUMBER: {
  //   field: "select_account_num",
  //   header: "Select Account Number",
  //   style: { width: "15%" },
  // },
  ACCOUNT_NAME: {
    field: "account_name",
    header: "Account Name",
    style: { width: "18%" },
  },
  ACCOUNT_NUMBER: {
    field: "account_number",
    header: "Account Number",
    style: { width: "12%" },
  },
  ACCOUNT_STATUS: {
    field: "account_status",
    header: "Account Status",
    style: { width: "12%" },
  },
  WEBSITE: { field: "website", header: "Website", style: { width: "12%" } },
  ADDRESS: {
    field: "address",
    header: "Address (City & State)",
    style: { width: "12%" },
  },
  TYPE: { field: "type", header: "Type", style: { width: "11%" } },
  DEFINITIVE_ID: {
    field: "definitive_id",
    header: "Definitive ID",
    style: { width: "9%" },
  },
};

export const hospitalDataNew = [
  {
    id: "HOSP01",
    accountName: "City Care Hospital",
    accountStatus: "Active",
    address: {
      street: "123 Main St",
      city: "Los Angeles",
      state: "CA",
      zipCode: "90001",
      country: "USA",
    },
    definitiveId: "12345678",
    accountNumber: "1023",
    website: "www.citycarehospital.com",
    hospitalType: "Cardiac Surgery",
  },
  {
    id: "HOSP02",
    accountName: "Sunrise Health Center",
    accountStatus: "Inactive",
    address: {
      street: "456 Elm St",
      city: "Miami",
      state: "FL",
      zipCode: "33101",
      country: "USA",
    },
    definitiveId: "23456789",
    accountNumber: "2045",
    website: "www.sunrisehealth.com",
    hospitalType: "Orthopedic Surgery",
  },
  {
    id: "HOSP03",
    accountName: "Green Valley Medical",
    accountStatus: "Active",
    address: {
      street: "789 Oak St",
      city: "Dallas",
      state: "TX",
      zipCode: "75201",
      country: "USA",
    },
    definitiveId: "34567890",
    accountNumber: "3098",
    website: "www.greenvalleymed.com",
    hospitalType: "Pediatric Surgery",
  },
  {
    id: "HOSP04",
    accountName: "Healing Touch Clinic",
    accountStatus: "Active",
    address: {
      street: "321 Pine Ave",
      city: "Seattle",
      state: "WA",
      zipCode: "98101",
      country: "USA",
    },
    definitiveId: "45678901",
    accountNumber: "4056",
    website: "www.healingtouchclinic.com",
    hospitalType: "Neurosurgery",
  },
  {
    id: "HOSP05",
    accountName: "Silverline Hospital",
    accountStatus: "Inactive",
    address: {
      street: "654 Cedar Blvd",
      city: "Chicago",
      state: "IL",
      zipCode: "60601",
      country: "USA",
    },
    definitiveId: "56789012",
    accountNumber: "5023",
    website: "www.silverlinehospital.com",
    hospitalType: "Plastic Surgery",
  },
  {
    id: "HOSP06",
    accountName: "Mercy Wellness Center",
    accountStatus: "Active",
    address: {
      street: "789 Birch Rd",
      city: "Boston",
      state: "MA",
      zipCode: "02108",
      country: "USA",
    },
    definitiveId: "67890123",
    accountNumber: "6034",
    website: "www.mercywellness.com",
    hospitalType: "Cardiac Surgery",
  },
  {
    id: "HOSP07",
    accountName: "Blue Horizon Medical",
    accountStatus: "Active",
    address: {
      street: "987 Maple Dr",
      city: "Houston",
      state: "TX",
      zipCode: "77001",
      country: "USA",
    },
    definitiveId: "78901234",
    accountNumber: "7012",
    website: "www.bluehorizonmedical.com",
    hospitalType: "Gastroenterology Surgery",
  },
  {
    id: "HOSP08",
    accountName: "Wellness Springs Hospital",
    accountStatus: "Inactive",
    address: {
      street: "123 Aspen Ln",
      city: "Denver",
      state: "CO",
      zipCode: "80202",
      country: "USA",
    },
    definitiveId: "89012345",
    accountNumber: "8024",
    website: "www.wellnesssprings.com",
    hospitalType: "General Surgery",
  },
  {
    id: "HOSP09",
    accountName: "Pinewood Health Center",
    accountStatus: "Active",
    address: {
      street: "456 Willow St",
      city: "Phoenix",
      state: "AZ",
      zipCode: "85001",
      country: "USA",
    },
    definitiveId: "90123456",
    accountNumber: "9034",
    website: "www.pinewoodhealth.com",
    hospitalType: "Orthopedic Surgery",
  },
  {
    id: "HOSP10",
    accountName: "Starlight Medical",
    accountStatus: "Inactive",
    address: {
      street: "321 Redwood Ct",
      city: "San Diego",
      state: "CA",
      zipCode: "92101",
      country: "USA",
    },
    definitiveId: "01234567",
    accountNumber: "1005",
    website: "www.starlightmedical.com",
    hospitalType: "Pediatric Surgery",
  },
  {
    id: "HOSP11",
    accountName: "Evergreen Clinic",
    accountStatus: "Active",
    address: {
      street: "654 Palm Ave",
      city: "Orlando",
      state: "FL",
      zipCode: "32801",
      country: "USA",
    },
    definitiveId: "11234567",
    accountNumber: "1106",
    website: "www.evergreenclinic.com",
    hospitalType: "Neurosurgery",
  },
  {
    id: "HOSP12",
    accountName: "Summit Health Hospital",
    accountStatus: "Active",
    address: {
      street: "789 Spruce Blvd",
      city: "Atlanta",
      state: "GA",
      zipCode: "30301",
      country: "USA",
    },
    definitiveId: "22345678",
    accountNumber: "1207",
    website: "www.summithealth.com",
    hospitalType: "Cardiac Surgery",
  },
  {
    id: "HOSP13",
    accountName: "Harmony Health Center",
    accountStatus: "Inactive",
    address: {
      street: "987 Cypress Ln",
      city: "Charlotte",
      state: "NC",
      zipCode: "28202",
      country: "USA",
    },
    definitiveId: "33456789",
    accountNumber: "1308",
    website: "www.harmonyhealth.com",
    hospitalType: "Orthopedic Surgery",
  },
  {
    id: "HOSP14",
    accountName: "Bright Care Hospital",
    accountStatus: "Active",
    address: {
      street: "123 Willow Dr",
      city: "Tampa",
      state: "FL",
      zipCode: "33601",
      country: "USA",
    },
    definitiveId: "44567890",
    accountNumber: "1409",
    website: "www.brightcare.com",
    hospitalType: "Plastic Surgery",
  },
  {
    id: "HOSP15",
    accountName: "Oakwood Medical Center",
    accountStatus: "Inactive",
    address: {
      street: "654 Ash St",
      city: "Nashville",
      state: "TN",
      zipCode: "37201",
      country: "USA",
    },
    definitiveId: "55678901",
    accountNumber: "1501",
    website: "www.oakwoodmed.com",
    hospitalType: "Gastroenterology Surgery",
  },
  {
    id: "HOSP16",
    accountName: "Tranquility Hospital",
    accountStatus: "Active",
    address: {
      street: "987 Dogwood Ct",
      city: "Portland",
      state: "OR",
      zipCode: "97201",
      country: "USA",
    },
    definitiveId: "66789012",
    accountNumber: "1602",
    website: "www.tranquilityhospital.com",
    hospitalType: "General Surgery",
  },
  {
    id: "HOSP17",
    accountName: "CareFirst Clinic",
    accountStatus: "Inactive",
    address: {
      street: "321 Magnolia Ln",
      city: "Austin",
      state: "TX",
      zipCode: "73301",
      country: "USA",
    },
    definitiveId: "77890123",
    accountNumber: "1703",
    website: "www.carefirstclinic.com",
    hospitalType: "Cardiac Surgery",
  },
  {
    id: "HOSP18",
    accountName: "Healthwise Hospital",
    accountStatus: "Active",
    address: {
      street: "654 Hickory St",
      city: "San Francisco",
      state: "CA",
      zipCode: "94101",
      country: "USA",
    },
    definitiveId: "88901234",
    accountNumber: "1804",
    website: "www.healthwisehospital.com",
    hospitalType: "Neurosurgery",
  },
  {
    id: "HOSP19",
    accountName: "Mountainview Health Center",
    accountStatus: "Active",
    address: {
      street: "987 Fir Ln",
      city: "Salt Lake City",
      state: "UT",
      zipCode: "84101",
      country: "USA",
    },
    definitiveId: "99012345",
    accountNumber: "1905",
    website: "www.mountainviewhealth.com",
    hospitalType: "Orthopedic Surgery",
  },
  {
    id: "HOSP20",
    accountName: "Hopewell Medical",
    accountStatus: "Inactive",
    address: {
      street: "321 Sycamore Ave",
      city: "Indianapolis",
      state: "IN",
      zipCode: "46201",
      country: "USA",
    },
    definitiveId: "10123456",
    accountNumber: "2006",
    website: "www.hopewellmedical.com",
    hospitalType: "Plastic Surgery",
  },
];

export const facilityAdmins = [
  {
    fullName: "John Doe",
    title: "Employee",
    phoneNumber: "123-456-7890",
    accountName: "City Care Hospital",
    hospitalId: "HOSP01",
  },
  {
    fullName: "Jane Smith",
    title: "HR Manager",
    phoneNumber: "234-567-8901",
    accountName: "City Care Hospital",
    hospitalId: "HOSP01",
  },
  {
    fullName: "Michael Brown",
    title: "Accountant",
    phoneNumber: "345-678-9012",
    accountName: "City Care Hospital",
    hospitalId: "HOSP01",
  },

  {
    fullName: "Emily Davis",
    title: "Employee",
    phoneNumber: "456-789-0123",
    accountName: "Sunrise Health Center",
    hospitalId: "HOSP02",
  },
  {
    fullName: "Robert Johnson",
    title: "Hiring Manager",
    phoneNumber: "567-890-1234",
    accountName: "Sunrise Health Center",
    hospitalId: "HOSP02",
  },
  {
    fullName: "Sophia Martinez",
    title: "Accountant",
    phoneNumber: "678-901-2345",
    accountName: "Sunrise Health Center",
    hospitalId: "HOSP02",
  },

  {
    fullName: "James Wilson",
    title: "Employee",
    phoneNumber: "789-012-3456",
    accountName: "Green Valley Medical",
    hospitalId: "HOSP03",
  },
  {
    fullName: "Olivia Taylor",
    title: "HR Manager",
    phoneNumber: "890-123-4567",
    accountName: "Green Valley Medical",
    hospitalId: "HOSP03",
  },

  {
    fullName: "William Anderson",
    title: "Employee",
    phoneNumber: "901-234-5678",
    accountName: "Healing Touch Clinic",
    hospitalId: "HOSP04",
  },
  {
    fullName: "Isabella Thomas",
    title: "Hiring Director",
    phoneNumber: "012-345-6789",
    accountName: "Healing Touch Clinic",
    hospitalId: "HOSP04",
  },
  {
    fullName: "Emma Garcia",
    title: "Accountant",
    phoneNumber: "123-456-7891",
    accountName: "Healing Touch Clinic",
    hospitalId: "HOSP04",
  },

  {
    fullName: "Lucas Moore",
    title: "Employee",
    phoneNumber: "234-567-8902",
    accountName: "Silverline Hospital",
    hospitalId: "HOSP05",
  },
  {
    fullName: "Mia Martinez",
    title: "HR Manager",
    phoneNumber: "345-678-9013",
    accountName: "Silverline Hospital",
    hospitalId: "HOSP05",
  },

  {
    fullName: "Benjamin Jackson",
    title: "Employee",
    phoneNumber: "456-789-0124",
    accountName: "Mercy Wellness Center",
    hospitalId: "HOSP06",
  },
  {
    fullName: "Ava Lee",
    title: "Accountant",
    phoneNumber: "567-890-1235",
    accountName: "Mercy Wellness Center",
    hospitalId: "HOSP06",
  },
  {
    fullName: "Charlotte Harris",
    title: "Hiring Manager",
    phoneNumber: "678-901-2346",
    accountName: "Mercy Wellness Center",
    hospitalId: "HOSP06",
  },

  {
    fullName: "Elijah Young",
    title: "Employee",
    phoneNumber: "789-012-3457",
    accountName: "Blue Horizon Medical",
    hospitalId: "HOSP07",
  },
  {
    fullName: "Amelia Walker",
    title: "Hiring Director",
    phoneNumber: "890-123-4568",
    accountName: "Blue Horizon Medical",
    hospitalId: "HOSP07",
  },

  {
    fullName: "Daniel Hall",
    title: "Employee",
    phoneNumber: "901-234-5679",
    accountName: "Wellness Springs Hospital",
    hospitalId: "HOSP08",
  },
  {
    fullName: "Chloe Allen",
    title: "HR Manager",
    phoneNumber: "012-345-6781",
    accountName: "Wellness Springs Hospital",
    hospitalId: "HOSP08",
  },
  {
    fullName: "Victoria King",
    title: "Accountant",
    phoneNumber: "123-456-7892",
    accountName: "Wellness Springs Hospital",
    hospitalId: "HOSP08",
  },

  {
    fullName: "Henry Scott",
    title: "Employee",
    phoneNumber: "234-567-8903",
    accountName: "Pinewood Health Center",
    hospitalId: "HOSP09",
  },
  {
    fullName: "Evelyn Wright",
    title: "Hiring Manager",
    phoneNumber: "345-678-9014",
    accountName: "Pinewood Health Center",
    hospitalId: "HOSP09",
  },

  {
    fullName: "Alexander Lopez",
    title: "Employee",
    phoneNumber: "456-789-0125",
    accountName: "Starlight Medical",
    hospitalId: "HOSP10",
  },
  {
    fullName: "Harper Hill",
    title: "Accountant",
    phoneNumber: "567-890-1236",
    accountName: "Starlight Medical",
    hospitalId: "HOSP10",
  },

  {
    fullName: "Sebastian Adams",
    title: "Employee",
    phoneNumber: "678-901-2347",
    accountName: "Evergreen Clinic",
    hospitalId: "HOSP11",
  },
  {
    fullName: "Lily Nelson",
    title: "HR Manager",
    phoneNumber: "789-012-3458",
    accountName: "Evergreen Clinic",
    hospitalId: "HOSP11",
  },

  {
    fullName: "Logan Carter",
    title: "Employee",
    phoneNumber: "890-123-4569",
    accountName: "Summit Health Hospital",
    hospitalId: "HOSP12",
  },
  {
    fullName: "Zoey Mitchell",
    title: "Hiring Director",
    phoneNumber: "901-234-5671",
    accountName: "Summit Health Hospital",
    hospitalId: "HOSP12",
  },

  {
    fullName: "Jack Perez",
    title: "Employee",
    phoneNumber: "012-345-6782",
    accountName: "Harmony Health Center",
    hospitalId: "HOSP13",
  },
  {
    fullName: "Ella Roberts",
    title: "HR Manager",
    phoneNumber: "123-456-7893",
    accountName: "Harmony Health Center",
    hospitalId: "HOSP13",
  },
  {
    fullName: "Scarlett Phillips",
    title: "Accountant",
    phoneNumber: "234-567-8904",
    accountName: "Harmony Health Center",
    hospitalId: "HOSP13",
  },

  {
    fullName: "David Baker",
    title: "Employee",
    phoneNumber: "345-678-9015",
    accountName: "Bright Care Hospital",
    hospitalId: "HOSP14",
  },
  {
    fullName: "Avery Torres",
    title: "HR Manager",
    phoneNumber: "456-789-0126",
    accountName: "Bright Care Hospital",
    hospitalId: "HOSP14",
  },

  {
    fullName: "Matthew Peterson",
    title: "Employee",
    phoneNumber: "567-890-1237",
    accountName: "Oakwood Medical Center",
    hospitalId: "HOSP15",
  },
  {
    fullName: "Grace Reed",
    title: "Accountant",
    phoneNumber: "678-901-2348",
    accountName: "Oakwood Medical Center",
    hospitalId: "HOSP15",
  },

  {
    fullName: "Joseph Morgan",
    title: "Employee",
    phoneNumber: "789-012-3459",
    accountName: "Tranquility Hospital",
    hospitalId: "HOSP16",
  },
  {
    fullName: "Addison Cooper",
    title: "Hiring Manager",
    phoneNumber: "890-123-4561",
    accountName: "Tranquility Hospital",
    hospitalId: "HOSP16",
  },

  {
    fullName: "Andrew Rivera",
    title: "Employee",
    phoneNumber: "901-234-5672",
    accountName: "CareFirst Clinic",
    hospitalId: "HOSP17",
  },
  {
    fullName: "Madison Bailey",
    title: "Accountant",
    phoneNumber: "012-345-6783",
    accountName: "CareFirst Clinic",
    hospitalId: "HOSP17",
  },

  {
    fullName: "Joshua Brooks",
    title: "Employee",
    phoneNumber: "123-456-7894",
    accountName: "Healthwise Hospital",
    hospitalId: "HOSP18",
  },
  {
    fullName: "Camila Rivera",
    title: "HR Manager",
    phoneNumber: "234-567-8905",
    accountName: "Healthwise Hospital",
    hospitalId: "HOSP18",
  },

  {
    fullName: "Ethan Jenkins",
    title: "Employee",
    phoneNumber: "345-678-9016",
    accountName: "Mountainview Health Center",
    hospitalId: "HOSP19",
  },
  {
    fullName: "Abigail Kelly",
    title: "Hiring Director",
    phoneNumber: "456-789-0127",
    accountName: "Mountainview Health Center",
    hospitalId: "HOSP19",
  },

  {
    fullName: "Oliver Bennett",
    title: "Employee",
    phoneNumber: "567-890-1238",
    accountName: "Hopewell Medical",
    hospitalId: "HOSP20",
  },
  {
    fullName: "Sofia Howard",
    title: "HR Manager",
    phoneNumber: "678-901-2349",
    accountName: "Hopewell Medical",
    hospitalId: "HOSP20",
  },
];

export const facilitiData = [
  {
    id: 1,
    account_number: 1231,
    hospitalName: "Green Valley Hospital",
    address: {
      street: "123 Green Valley Road",
      city: "New York",
      state: "NY",
      zipCode: "10001",
      country: "USA",
    },
    contact: {
      phone: "+1-555-1234",
      email: "contact@greenvalleyhospital.com",
      fax: "+1-555-5678",
    },
    hospitalType: "Specialty",
    status: "Active",
    admin: {
      fullName: "Sarah Jones",
      jobRole: "Chief Medical Officer",
      email: "sarah.jones@greenvalleyhospital.com",
      phone: "+1-555-1122",
    },
  },
  {
    id: 2,
    account_number: 4324,
    hospitalName: "Lakeside General",
    address: {
      street: "456 Lakeside Drive",
      city: "Chicago",
      state: "IL",
      zipCode: "60601",
      country: "USA",
    },
    contact: {
      phone: "+1-555-2345",
      email: "contact@lakesidegeneral.com",
      fax: "+1-555-6789",
    },
    hospitalType: "General",
    status: "On Hold",
    admin: {
      fullName: "Emily Carter",
      jobRole: "Hospital Administrator",
      email: "emily.carter@lakesidegeneral.com",
      phone: "+1-555-2233",
    },
  },
  {
    id: 3,
    account_number: 1231,
    hospitalName: "Sunrise Medical Center",
    address: {
      street: "789 Sunrise Ave",
      city: "Los Angeles",
      state: "CA",
      zipCode: "90001",
      country: "USA",
    },
    contact: {
      phone: "+1-555-3456",
      email: "contact@sunrisemedical.com",
      fax: "+1-555-7890",
    },
    hospitalType: "Specialty",
    status: "Active",
    admin: {
      fullName: "Michael Brown",
      jobRole: "Director of Operations",
      email: "michael.brown@sunrisemedical.com",
      phone: "+1-555-3344",
    },
  },
  {
    id: 4,
    account_number: 3453,
    hospitalName: "Oceanview Health",
    address: {
      street: "101 Oceanview Blvd",
      city: "Miami",
      state: "FL",
      zipCode: "33101",
      country: "USA",
    },
    contact: {
      phone: "+1-555-4567",
      email: "contact@oceanviewhealth.com",
      fax: "+1-555-8901",
    },
    hospitalType: "General",
    status: "Active",
    admin: {
      fullName: "Alice White",
      jobRole: "Chief Executive Officer",
      email: "alice.white@oceanviewhealth.com",
      phone: "+1-555-4455",
    },
  },
  {
    id: 5,
    account_number: 4564,
    hospitalName: "City Heart Center",
    address: {
      street: "202 City Blvd",
      city: "Dallas",
      state: "TX",
      zipCode: "75201",
      country: "USA",
    },
    contact: {
      phone: "+1-555-5678",
      email: "contact@cityheartcenter.com",
      fax: "+1-555-9012",
    },
    hospitalType: "Specialty",
    status: "On Hold",
    admin: {
      fullName: "John Anderson",
      jobRole: "Medical Director",
      email: "john.anderson@cityheartcenter.com",
      phone: "+1-555-5566",
    },
  },
  {
    id: 6,
    account_number: 7567,
    hospitalName: "Mountain Ridge Hospital",
    address: {
      street: "303 Ridge Road",
      city: "Denver",
      state: "CO",
      zipCode: "80201",
      country: "USA",
    },
    contact: {
      phone: "+1-555-6789",
      email: "contact@mountainridgehospital.com",
      fax: "+1-555-0123",
    },
    hospitalType: "General",
    status: "Active",
    admin: {
      fullName: "Laura Harris",
      jobRole: "Head of Administration",
      email: "laura.harris@mountainridgehospital.com",
      phone: "+1-555-6677",
    },
  },
  {
    id: 7,
    account_number: 4564,
    hospitalName: "Valley Community Hospital",
    address: {
      street: "404 Valley Lane",
      city: "Phoenix",
      state: "AZ",
      zipCode: "85001",
      country: "USA",
    },
    contact: {
      phone: "+1-555-7890",
      email: "contact@valleycommunity.com",
      fax: "+1-555-1234",
    },
    hospitalType: "Specialty",
    status: "On Hold",
    admin: {
      fullName: "Robert Martinez",
      jobRole: "Director of Health Services",
      email: "robert.martinez@valleycommunity.com",
      phone: "+1-555-7788",
    },
  },
  {
    id: 8,
    account_number: 3453,
    hospitalName: "Westside Medical Group",
    address: {
      street: "505 Westside Street",
      city: "Houston",
      state: "TX",
      zipCode: "77001",
      country: "USA",
    },
    contact: {
      phone: "+1-555-8901",
      email: "contact@westsidemedical.com",
      fax: "+1-555-2345",
    },
    hospitalType: "General",
    status: "Active",
    admin: {
      fullName: "Karen Lopez",
      jobRole: "Medical Administrator",
      email: "karen.lopez@westsidemedical.com",
      phone: "+1-555-8899",
    },
  },
  {
    id: 9,
    account_number: 1231,
    hospitalName: "Parkway Medical Center",
    address: {
      street: "909 Parkway Street",
      city: "Boston",
      state: "MA",
      zipCode: "02108",
      country: "USA",
    },
    contact: {
      phone: "+1-555-0123",
      email: "contact@parkwaymedical.com",
      fax: "+1-555-4567",
    },
    hospitalType: "General",
    status: "On Hold",
    admin: {
      fullName: "Linda Park",
      jobRole: "Head of Administration",
      email: "linda.park@parkwaymedical.com",
      phone: "+1-555-9911",
    },
  },
  {
    id: 10,
    account_number: 1231,
    hospitalName: "Summit View Hospital",
    address: {
      street: "1010 Summit Drive",
      city: "Seattle",
      state: "WA",
      zipCode: "98101",
      country: "USA",
    },
    contact: {
      phone: "+1-555-1234",
      email: "contact@summitview.com",
      fax: "+1-555-5678",
    },
    hospitalType: "Specialty",
    status: "Active",
    admin: {
      fullName: "James Wong",
      jobRole: "Chief Executive Officer",
      email: "james.wong@summitview.com",
      phone: "+1-555-9922",
    },
  },
  {
    id: 11,
    account_number: 6567,
    hospitalName: "Pinecrest Health",
    address: {
      street: "1111 Pinecrest Road",
      city: "San Francisco",
      state: "CA",
      zipCode: "94101",
      country: "USA",
    },
    contact: {
      phone: "+1-555-2345",
      email: "contact@pinecresthealth.com",
      fax: "+1-555-6789",
    },
    hospitalType: "General",
    status: "On Hold",
    admin: {
      fullName: "Grace Lee",
      jobRole: "Medical Director",
      email: "grace.lee@pinecresthealth.com",
      phone: "+1-555-9933",
    },
  },
  {
    id: 12,
    account_number: 5454,
    hospitalName: "Central Valley Hospital",
    address: {
      street: "1212 Valley Road",
      city: "Sacramento",
      state: "CA",
      zipCode: "94203",
      country: "USA",
    },
    contact: {
      phone: "+1-555-3456",
      email: "contact@centralvalleyhospital.com",
      fax: "+1-555-7890",
    },
    hospitalType: "Specialty",
    status: "Active",
    admin: {
      fullName: "Mark Evans",
      jobRole: "Hospital Administrator",
      email: "mark.evans@centralvalleyhospital.com",
      phone: "+1-555-9944",
    },
  },
  {
    id: 13,
    account_number: 1468,
    hospitalName: "Silver Lake Medical",
    address: {
      street: "1313 Silver Lake Rd",
      city: "Orlando",
      state: "FL",
      zipCode: "32801",
      country: "USA",
    },
    contact: {
      phone: "+1-555-4567",
      email: "contact@silverlake.com",
      fax: "+1-555-8901",
    },
    hospitalType: "General",
    status: "On Hold",
    admin: {
      fullName: "Rachel Adams",
      jobRole: "Chief of Staff",
      email: "rachel.adams@silverlake.com",
      phone: "+1-555-9955",
    },
  },
  {
    id: 14,
    account_number: 9803,
    hospitalName: "Eastside Hospital",
    address: {
      street: "1414 Eastside St",
      city: "Philadelphia",
      state: "PA",
      zipCode: "19102",
      country: "USA",
    },
    contact: {
      phone: "+1-555-5678",
      email: "contact@eastsidehospital.com",
      fax: "+1-555-9012",
    },
    hospitalType: "Specialty",
    status: "Active",
    admin: {
      fullName: "Daniel Kim",
      jobRole: "Director of Operations",
      email: "daniel.kim@eastsidehospital.com",
      phone: "+1-555-9966",
    },
  },
  {
    id: 15,
    account_number: 2634,
    hospitalName: "West Hill Health Center",
    address: {
      street: "1515 West Hill Dr",
      city: "Austin",
      state: "TX",
      zipCode: "73301",
      country: "USA",
    },
    contact: {
      phone: "+1-555-6789",
      email: "contact@westhillhealth.com",
      fax: "+1-555-0123",
    },
    hospitalType: "General",
    status: "On Hold",
    admin: {
      fullName: "Kevin Mitchell",
      jobRole: "Chief Medical Officer",
      email: "kevin.mitchell@westhillhealth.com",
      phone: "+1-555-9977",
    },
  },
  {
    id: 16,
    account_number: 1111,
    hospitalName: "Northside Medical Center",
    address: {
      street: "1616 Northside Blvd",
      city: "Nashville",
      state: "TN",
      zipCode: "37201",
      country: "USA",
    },
    contact: {
      phone: "+1-555-7890",
      email: "contact@northsidemedical.com",
      fax: "+1-555-1234",
    },
    hospitalType: "Specialty",
    status: "Active",
    admin: {
      fullName: "Stephanie Baker",
      jobRole: "Medical Director",
      email: "stephanie.baker@northsidemedical.com",
      phone: "+1-555-9988",
    },
  },
  {
    id: 17,
    account_number: 2222,
    hospitalName: "Coastal Care Hospital",
    address: {
      street: "1717 Coastal Blvd",
      city: "San Diego",
      state: "CA",
      zipCode: "92101",
      country: "USA",
    },
    contact: {
      phone: "+1-555-8901",
      email: "contact@coastalcare.com",
      fax: "+1-555-2345",
    },
    hospitalType: "General",
    status: "On Hold",
    admin: {
      fullName: "Anna Taylor",
      jobRole: "Head of Administration",
      email: "anna.taylor@coastalcare.com",
      phone: "+1-555-9999",
    },
  },
];

export const hospitalData = [
  {
    hospital_name: "Mercy General",
    location: {
      street: "123 Maple St.",
      city: "New York",
      state: "NY",
      zip_code: "10001",
      country: "USA",
    },
    contact: "212-555-0101",
    email: "contact@mercygen.com",
    hospital_type: "General",
    general_contact: "212-555-0101",
    fax_number: "212-555-0202",
  },
  {
    hospital_name: "City Health Clinic",
    location: {
      street: "456 Oak Ave.",
      city: "Los Angeles",
      state: "CA",
      zip_code: "90001",
      country: "USA",
    },
    contact: "310-555-0111",
    email: "info@cityhealth.com",
    hospital_type: "Specialty",
    general_contact: "310-555-0111",
    fax_number: "310-555-0212",
  },
  {
    hospital_name: "Riverside Hospital",
    location: {
      street: "789 Pine Rd.",
      city: "Chicago",
      state: "IL",
      zip_code: "60601",
      country: "USA",
    },
    contact: "312-555-0121",
    email: "contact@riverside.com",
    hospital_type: "General",
    general_contact: "312-555-0121",
    fax_number: "312-555-0223",
  },
  {
    hospital_name: "Green Valley Med",
    location: {
      street: "321 Birch Blvd.",
      city: "Houston",
      state: "TX",
      zip_code: "77001",
      country: "USA",
    },
    contact: "713-555-0131",
    email: "info@greenvalley.com",
    hospital_type: "Children's",
    general_contact: "713-555-0131",
    fax_number: "713-555-0234",
  },
  {
    hospital_name: "St. Luke's Center",
    location: {
      street: "654 Cedar Dr.",
      city: "Phoenix",
      state: "AZ",
      zip_code: "85001",
      country: "USA",
    },
    contact: "602-555-0141",
    email: "contact@stlukes.com",
    hospital_type: "General",
    general_contact: "602-555-0141",
    fax_number: "602-555-0245",
  },
  {
    hospital_name: "Redwood Medical",
    location: {
      street: "234 Elm St.",
      city: "San Francisco",
      state: "CA",
      zip_code: "94101",
      country: "USA",
    },
    contact: "415-555-0151",
    email: "info@redwoodmed.com",
    hospital_type: "Specialty",
    general_contact: "415-555-0151",
    fax_number: "415-555-0256",
  },
  {
    hospital_name: "Blue Mountain Clinic",
    location: {
      street: "876 Ash Ln.",
      city: "Denver",
      state: "CO",
      zip_code: "80201",
      country: "USA",
    },
    contact: "303-555-0161",
    email: "contact@bluemountain.com",
    hospital_type: "General",
    general_contact: "303-555-0161",
    fax_number: "303-555-0267",
  },
  {
    hospital_name: "Coastal Health Center",
    location: {
      street: "543 Willow Ave.",
      city: "Miami",
      state: "FL",
      zip_code: "33101",
      country: "USA",
    },
    contact: "305-555-0171",
    email: "info@coastalhealth.com",
    hospital_type: "Rehabilitation",
    general_contact: "305-555-0171",
    fax_number: "305-555-0278",
  },
  {
    hospital_name: "Pine Hill Medical Center",
    location: {
      street: "678 Spruce St.",
      city: "Boston",
      state: "MA",
      zip_code: "02101",
      country: "USA",
    },
    contact: "617-555-0181",
    email: "contact@pinehill.com",
    hospital_type: "General",
    general_contact: "617-555-0181",
    fax_number: "617-555-0289",
  },
  {
    hospital_name: "Cedar Grove Hospital",
    location: {
      street: "345 Poplar Dr.",
      city: "Seattle",
      state: "WA",
      zip_code: "98101",
      country: "USA",
    },
    contact: "206-555-0191",
    email: "info@cedargrove.com",
    hospital_type: "Trauma",
    general_contact: "206-555-0191",
    fax_number: "206-555-0290",
  },
  {
    hospital_name: "Lakeside Medical",
    location: {
      street: "123 Lake St.",
      city: "Orlando",
      state: "FL",
      zip_code: "32801",
      country: "USA",
    },
    contact: "407-555-0201",
    email: "contact@lakesidemed.com",
    hospital_type: "General",
    general_contact: "407-555-0201",
    fax_number: "407-555-0302",
  },
  {
    hospital_name: "North Hills Hospital",
    location: {
      street: "789 North Blvd.",
      city: "Dallas",
      state: "TX",
      zip_code: "75201",
      country: "USA",
    },
    contact: "214-555-0211",
    email: "info@northhills.com",
    hospital_type: "General",
    general_contact: "214-555-0211",
    fax_number: "214-555-0313",
  },
  {
    hospital_name: "Bay Area Medical Center",
    location: {
      street: "456 Bay Rd.",
      city: "San Jose",
      state: "CA",
      zip_code: "95101",
      country: "USA",
    },
    contact: "408-555-0221",
    email: "contact@bayareamed.com",
    hospital_type: "Specialty",
    general_contact: "408-555-0221",
    fax_number: "408-555-0324",
  },
  {
    hospital_name: "Westside Medical Clinic",
    location: {
      street: "321 West St.",
      city: "Austin",
      state: "TX",
      zip_code: "73301",
      country: "USA",
    },
    contact: "512-555-0231",
    email: "info@westsidemed.com",
    hospital_type: "Children's",
    general_contact: "512-555-0231",
    fax_number: "512-555-0335",
  },
  {
    hospital_name: "Sunnybrook Hospital",
    location: {
      street: "654 Sunny St.",
      city: "San Diego",
      state: "CA",
      zip_code: "92101",
      country: "USA",
    },
    contact: "619-555-0241",
    email: "contact@sunnybrook.com",
    hospital_type: "General",
    general_contact: "619-555-0241",
    fax_number: "619-555-0346",
  },
  {
    hospital_name: "Midtown Health",
    location: {
      street: "456 Center Rd.",
      city: "Nashville",
      state: "TN",
      zip_code: "37201",
      country: "USA",
    },
    contact: "615-555-0251",
    email: "info@midtownhealth.com",
    hospital_type: "General",
    general_contact: "615-555-0251",
    fax_number: "615-555-0357",
  },
  {
    hospital_name: "Hillside General",
    location: {
      street: "789 Hilltop Ave.",
      city: "Salt Lake City",
      state: "UT",
      zip_code: "84101",
      country: "USA",
    },
    contact: "801-555-0261",
    email: "contact@hillsidegen.com",
    hospital_type: "General",
    general_contact: "801-555-0261",
    fax_number: "801-555-0368",
  },
  {
    hospital_name: "Parkview Medical Center",
    location: {
      street: "123 Park Ave.",
      city: "Las Vegas",
      state: "NV",
      zip_code: "89101",
      country: "USA",
    },
    contact: "702-555-0271",
    email: "info@parkview.com",
    hospital_type: "Rehabilitation",
    general_contact: "702-555-0271",
    fax_number: "702-555-0379",
  },
  {
    hospital_name: "Cypress Health Clinic",
    location: {
      street: "654 Forest Dr.",
      city: "Charlotte",
      state: "NC",
      zip_code: "28201",
      country: "USA",
    },
    contact: "704-555-0281",
    email: "contact@cypresshealth.com",
    hospital_type: "General",
    general_contact: "704-555-0281",
    fax_number: "704-555-0380",
  },
  {
    hospital_name: "Willowbrook Hospital",
    location: {
      street: "987 Brook Rd.",
      city: "Philadelphia",
      state: "PA",
      zip_code: "19101",
      country: "USA",
    },
    contact: "215-555-0291",
    email: "info@willowbrook.com",
    hospital_type: "Trauma",
    general_contact: "215-555-0291",
    fax_number: "215-555-0391",
  },
];

export const iframeHospitalData = [
  {
    hospital_name: "City Hospital",
    reports: [
      {
        Sr_No: 1,
        report_name: "Cardiology Report",
        URL: "<iframe src='https://example.com/iframe/city-hospital/cardiology-report'></iframe>",
      },
      {
        Sr_No: 2,
        report_name: "Radiology Report",
        URL: "<iframe src='https://example.com/iframe/city-hospital/radiology-report'></iframe>",
      },
      {
        Sr_No: 3,
        report_name: "Neurology Report",
        URL: "<iframe src='https://example.com/iframe/city-hospital/neurology-report'></iframe>",
      },
    ],
  },
  {
    hospital_name: "Green Valley Medical Center",
    reports: [
      {
        Sr_No: 1,
        report_name: "Orthopedics Report",
        URL: "<iframe src='https://example.com/iframe/green-valley/orthopedics-report'></iframe>",
      },
      {
        Sr_No: 2,
        report_name: "Oncology Report",
        URL: "<iframe src='https://example.com/iframe/green-valley/oncology-report'></iframe>",
      },
      {
        Sr_No: 3,
        report_name: "Pediatrics Report",
        URL: "<iframe src='https://example.com/iframe/green-valley/pediatrics-report'></iframe>",
      },
    ],
  },
  {
    hospital_name: "Green",
    reports: [
      {
        Sr_No: 1,
        report_name: "Orthopedics Report",
        URL: "<iframe src='https://example.com/iframe/green-valley/orthopedics-report'></iframe>",
      },
      {
        Sr_No: 2,
        report_name: "Oncology Report",
        URL: "<iframe src='https://example.com/iframe/green-valley/oncology-report'></iframe>",
      },
      {
        Sr_No: 3,
        report_name: "Pediatrics Report",
        URL: "<iframe src='https://example.com/iframe/green-valley/pediatrics-report'></iframe>",
      },
    ],
  },
  {
    hospital_name: "Lakeside General",
    reports: [
      {
        Sr_No: 1,
        report_name: "Dermatology Report",
        URL: "<iframe src='https://example.com/iframe/lakeside-general/dermatology-report'></iframe>",
      },
      {
        Sr_No: 2,
        report_name: "Gynecology Report",
        URL: "<iframe src='https://example.com/iframe/lakeside-general/gynecology-report'></iframe>",
      },
      {
        Sr_No: 3,
        report_name: "Ophthalmology Report",
        URL: "<iframe src='https://example.com/iframe/lakeside-general/ophthalmology-report'></iframe>",
      },
    ],
  },
  {
    hospital_name: "Sunrise Health Center",
    reports: [
      {
        Sr_No: 1,
        report_name: "Gastroenterology Report",
        URL: "<iframe src='https://example.com/iframe/sunrise-health/gastroenterology-report'></iframe>",
      },
      {
        Sr_No: 2,
        report_name: "Cardiology Follow-up",
        URL: "<iframe src='https://example.com/iframe/sunrise-health/cardiology-follow-up'></iframe>",
      },
      {
        Sr_No: 3,
        report_name: "Radiology Diagnostics",
        URL: "<iframe src='https://example.com/iframe/sunrise-health/radiology-diagnostics'></iframe>",
      },
    ],
  },
  {
    hospital_name: "Sunrise Health",
    reports: [
      {
        Sr_No: 1,
        report_name: "Gastroenterology Report",
        URL: "<iframe src='https://example.com/iframe/sunrise-health/gastroenterology-report'></iframe>",
      },
      {
        Sr_No: 2,
        report_name: "Cardiology Follow-up",
        URL: "<iframe src='https://example.com/iframe/sunrise-health/cardiology-follow-up'></iframe>",
      },
      {
        Sr_No: 3,
        report_name: "Radiology Diagnostics",
        URL: "<iframe src='https://example.com/iframe/sunrise-health/radiology-diagnostics'></iframe>",
      },
    ],
  },
];

export const workSites = [
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
];

export const newWorksites = [
  {
    account_number: 2826097256,
    parent_account_number: 3527724424,
    account_name: "Stevens-Farmer",
    account_status: "Active",
    hospital_type: "Rehabilitation",
    website: "https://jacobson.info/",
    address: { city: "Port David", state: "Maryland" },
    definitive_id: 235987800,
  },
  {
    account_number: 8062576862,
    parent_account_number: 3658840588,
    account_name: "Fleming-Lopez",
    account_status: "Active",
    hospital_type: "General",
    website: "https://pruitt.com/",
    address: { city: "Bakertown", state: "Washington" },
    definitive_id: 919261050,
  },
  {
    account_number: 5598873681,
    parent_account_number: 4825007567,
    account_name: "Rice Ltd",
    account_status: "Inactive",
    hospital_type: "Rehabilitation",
    website: "https://www.welch.com/",
    address: { city: "Justinland", state: "Pennsylvania" },
    definitive_id: 782336694,
  },
  {
    account_number: 9514232585,
    parent_account_number: 8476562745,
    account_name: "Sharp-Lester",
    account_status: "Inactive",
    hospital_type: "Psychiatric",
    website: "http://castro-klein.com/",
    address: { city: "Rodriguezport", state: "Alabama" },
    definitive_id: 212698627,
  },
  {
    account_number: 6582338309,
    parent_account_number: 3826348975,
    account_name: "Miller-Brown",
    account_status: "Inactive",
    hospital_type: "General",
    website: "https://www.howe.com/",
    address: { city: "New Richard", state: "Nevada" },
    definitive_id: 898413882,
  },
  {
    account_number: 9578880236,
    parent_account_number: 4815029257,
    account_name: "Ryan and Sons",
    account_status: "Active",
    hospital_type: "Psychiatric",
    website: "http://nguyen-simpson.net/",
    address: { city: "North Sheilamouth", state: "Utah" },
    definitive_id: 482500606,
  },
  {
    account_number: 2041584654,
    parent_account_number: 2455525807,
    account_name: "Richmond and Sons",
    account_status: "Inactive",
    hospital_type: "Specialty",
    website: "https://dunn.com/",
    address: { city: "South Brittany", state: "Delaware" },
    definitive_id: 853556716,
  },
  {
    account_number: 5451916525,
    parent_account_number: 7792017930,
    account_name: "Perez, Lopez and Thornton",
    account_status: "Active",
    hospital_type: "Pediatric",
    website: "http://compton.com/",
    address: { city: "Patrickburgh", state: "Vermont" },
    definitive_id: 134499123,
  },
  {
    account_number: 5895430556,
    parent_account_number: 6294519228,
    account_name: "Mercado PLC",
    account_status: "Inactive",
    hospital_type: "General",
    website: "https://adams-johnson.com/",
    address: { city: "Port Adamville", state: "Iowa" },
    definitive_id: 744686779,
  },
  {
    account_number: 2185839730,
    parent_account_number: 6261867855,
    account_name: "Henson Ltd",
    account_status: "Active",
    hospital_type: "General",
    website: "http://ward.org/",
    address: { city: "Thomasport", state: "New Jersey" },
    definitive_id: 926464289,
  },
  {
    account_number: 3183501089,
    parent_account_number: 5430324617,
    account_name: "Arellano LLC",
    account_status: "Active",
    hospital_type: "Rehabilitation",
    website: "https://www.harris.com/",
    address: { city: "Colinshire", state: "Nevada" },
    definitive_id: 853339234,
  },
  {
    account_number: 4293929099,
    parent_account_number: 1265290176,
    account_name: "Baker-Peterson",
    account_status: "Active",
    hospital_type: "Specialty",
    website: "https://mcbride-pearson.info/",
    address: { city: "Stevenstown", state: "North Carolina" },
    definitive_id: 527442616,
  },
  {
    account_number: 7990139512,
    parent_account_number: 2688980027,
    account_name: "Schneider PLC",
    account_status: "Active",
    hospital_type: "General",
    website: "https://www.wong.biz/",
    address: { city: "Danielburgh", state: "Maryland" },
    definitive_id: 838285312,
  },
  {
    account_number: 2464435174,
    parent_account_number: 5585263084,
    account_name: "Salas-Roth",
    account_status: "Inactive",
    hospital_type: "Pediatric",
    website: "https://robbins-warren.com/",
    address: { city: "Port Carriemouth", state: "Alabama" },
    definitive_id: 934352374,
  },
  {
    account_number: 3160402379,
    parent_account_number: 6840716972,
    account_name: "Cook-Garcia",
    account_status: "Inactive",
    hospital_type: "Rehabilitation",
    website: "http://garcia-bautista.info/",
    address: { city: "Gibbsfort", state: "South Dakota" },
    definitive_id: 879778417,
  },
  {
    account_number: 1585304003,
    parent_account_number: 6371100086,
    account_name: "Robinson, Simmons and Smith",
    account_status: "Active",
    hospital_type: "Pediatric",
    website: "https://michael.com/",
    address: { city: "Lake Charleshaven", state: "Washington" },
    definitive_id: 880721688,
  },
  {
    account_number: 2012696247,
    parent_account_number: 1550787189,
    account_name: "Barrera, Dean and Perkins",
    account_status: "Inactive",
    hospital_type: "Rehabilitation",
    website: "https://www.davis.org/",
    address: { city: "North Michaelland", state: "Virginia" },
    definitive_id: 755725328,
  },
  {
    account_number: 3356659305,
    parent_account_number: 1283240481,
    account_name: "Bailey, Stanley and Gray",
    account_status: "Inactive",
    hospital_type: "Rehabilitation",
    website: "https://www.hudson-wise.org/",
    address: { city: "North Brianberg", state: "West Virginia" },
    definitive_id: 892110032,
  },
  {
    account_number: 6116999808,
    parent_account_number: 5193768621,
    account_name: "Brown-Gregory",
    account_status: "Inactive",
    hospital_type: "Rehabilitation",
    website: "http://spencer.info/",
    address: { city: "East Ann", state: "Missouri" },
    definitive_id: 609479539,
  },
  {
    account_number: 1386573415,
    parent_account_number: 8601143753,
    account_name: "Bryant LLC",
    account_status: "Inactive",
    hospital_type: "Specialty",
    website: "http://www.nelson-martinez.com/",
    address: { city: "North Tracytown", state: "Montana" },
    definitive_id: 701032737,
  },
];

export const worksiteUsers = [
  {
    userName: "John Doe",
    userType: "Admin",
    phoneNumber: "123-456-7890",
    accountName: "City Hospital",
    title: "Hospital Administrator",
    email: "johndoe@cityhospital.com",
  },
  {
    userName: "Jane Smith",
    userType: "Doctor",
    phoneNumber: "987-654-3210",
    accountName: "Green Valley Clinic",
    title: "Chief Surgeon",
    email: "janesmith@greenvalleyclinic.com",
  },
  {
    userName: "Michael Brown",
    userType: "Nurse",
    phoneNumber: "456-789-1234",
    accountName: "Blue River Hospital",
    title: "Senior Nurse",
    email: "michaelbrown@blueriverhospital.com",
  },
  {
    userName: "Emily Davis",
    userType: "Receptionist",
    phoneNumber: "321-654-9870",
    accountName: "Silver Oak Medical Center",
    title: "Front Desk Manager",
    email: "emilydavis@silveroak.com",
  },
  {
    userName: "Chris Wilson",
    userType: "Technician",
    phoneNumber: "789-123-4567",
    accountName: "Skyline Diagnostics",
    title: "Lab Technician",
    email: "chriswilson@skylinediagnostics.com",
  },
  {
    userName: "Patricia Taylor",
    userType: "Admin",
    phoneNumber: "654-987-3210",
    accountName: "Harmony Care",
    title: "HR Manager",
    email: "patriciataylor@harmonycare.com",
  },
  {
    userName: "James Anderson",
    userType: "Doctor",
    phoneNumber: "123-987-6543",
    accountName: "Sunrise Hospital",
    title: "Pediatrician",
    email: "jamesanderson@sunrisehospital.com",
  },
  {
    userName: "Laura Thomas",
    userType: "Nurse",
    phoneNumber: "456-123-7890",
    accountName: "Golden Valley Health",
    title: "Head Nurse",
    email: "laurathomas@goldenvalleyhealth.com",
  },
  {
    userName: "Daniel Martinez",
    userType: "Technician",
    phoneNumber: "789-456-1230",
    accountName: "Metro Labs",
    title: "X-ray Technician",
    email: "danielmartinez@metrolabs.com",
  },
  {
    userName: "Sophia Hernandez",
    userType: "Receptionist",
    phoneNumber: "321-789-6543",
    accountName: "Downtown Clinic",
    title: "Receptionist",
    email: "sophiahernandez@downtownclinic.com",
  },
  {
    userName: "Olivia White",
    userType: "Admin",
    phoneNumber: "654-321-9876",
    accountName: "River Valley Hospital",
    title: "Operations Manager",
    email: "oliviawhite@rivervalleyhospital.com",
  },
  {
    userName: "Ethan Harris",
    userType: "Doctor",
    phoneNumber: "987-321-6540",
    accountName: "Hilltop Medical",
    title: "Orthopedic Specialist",
    email: "ethanharris@hilltopmedical.com",
  },
  {
    userName: "Isabella Clark",
    userType: "Nurse",
    phoneNumber: "789-654-1239",
    accountName: "Oceanview Health",
    title: "Nurse Practitioner",
    email: "isabellaclark@oceanviewhealth.com",
  },
  {
    userName: "Alexander Lee",
    userType: "Technician",
    phoneNumber: "321-987-6547",
    accountName: "Mountain Labs",
    title: "Lab Assistant",
    email: "alexanderlee@mountainlabs.com",
  },
  {
    userName: "Mia Lewis",
    userType: "Receptionist",
    phoneNumber: "456-789-3211",
    accountName: "Lakeside Clinic",
    title: "Front Desk Staff",
    email: "mialewis@lakesideclinic.com",
  },
  {
    userName: "William Robinson",
    userType: "Admin",
    phoneNumber: "987-654-1238",
    accountName: "Evergreen Medical",
    title: "IT Administrator",
    email: "williamrobinson@evergreenmedical.com",
  },
  {
    userName: "Charlotte Walker",
    userType: "Doctor",
    phoneNumber: "654-123-7892",
    accountName: "Central Health",
    title: "Dermatologist",
    email: "charlottewalker@centralhealth.com",
  },
  {
    userName: "Benjamin Hall",
    userType: "Nurse",
    phoneNumber: "123-789-6543",
    accountName: "Northern Care",
    title: "Registered Nurse",
    email: "benjaminhall@northerncare.com",
  },
  {
    userName: "Abigail Young",
    userType: "Technician",
    phoneNumber: "789-123-4568",
    accountName: "Westside Labs",
    title: "Medical Imaging Specialist",
    email: "abigailyoung@westsidelabs.com",
  },
  {
    userName: "Lucas King",
    userType: "Receptionist",
    phoneNumber: "321-654-9871",
    accountName: "Eastside Clinic",
    title: "Clinic Coordinator",
    email: "lucasking@eastsideclinic.com",
  },
];
