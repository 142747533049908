// utils.js
export const getCurrentDate = () => {
    const now = new Date();
    return now
      .toISOString()
      .replace("Z", "")   // Remove the 'Z' at the end
      .replace("T", " "); // Replace the 'T' with a space for date-time format
  };

 export const indexTemplate = (rowData, options) => {
    return options.rowIndex + 1;
  };