import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk'; // Example middleware
 import rootReducer from './reducers/rootReducer';

// Ensure that `window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__` exists, otherwise use the regular compose
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Apply middleware (e.g., thunk) correctly
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))  // Ensure you are passing middleware correctly
);

export default store;