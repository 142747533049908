import { FaPlus } from 'react-icons/fa'
import '../../assets/css/AddButton.scss'
import React from 'react'

function AddButton(props) {
  return (
    <button className="add-btn" onClick={props.onClick}>
    <i>
      <FaPlus/>
    </i>
    {props.name}
  </button>
  )
}

export default AddButton