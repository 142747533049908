import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/IframePage.scss"

function IframePage() {
  const location = useLocation();
  const Iframe = location.state?.iframe;

  return (
    <div
      className="flex-grow-1 overflow-auto w-100 m-1"
      style={{ height: "70vh" }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: Iframe }} style={{
          width: "100%",
          height: "100%",
        }}
      ></div>
    </div>
  );
}

export default IframePage;
