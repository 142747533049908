import axios from 'axios';
// Create an Axios instance with a base URL and default headers

export const headersRequest = {
    "Content-Type": "application/json", // Setting the header
    client_id:"8ed163218d0a4eeba8226e1ec5f6fae9",
    client_secret:"4dC0D60b9Aa14074b9259781167B97F0"
  };

  export  const getFacilitySFList = async (facilityPrompt = "") => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/facilities?facilityPrompt=${facilityPrompt}&limit=20`;
      const response = await axios.get(url, {
        headers: headersRequest,
      });
       return response.data; // Return the data from the API response
    } catch (error) {
      console.error('Error fetching data with headers:', error);
      throw error; // Propagate the error
    }
  };
 
  export  const getAdminSFList = async (facilityId = "") => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users?facilityId=${facilityId}&offset=0&limit=20`;
      const response = await axios.get(url, {
        headers: headersRequest,
      });
       return response.data; // Return the data from the API response
    } catch (error) {
      console.error('Error fetching data with headers:', error);
      throw error; // Propagate the error
    }
  };

 