export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const SET_TOKEN = "SET_TOKEN";
export const SET_USER = "SET_USER";
export const CLEAR_AUTH = "CLEAR_AUTH";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const RESET_FACILITY_DATA = "RESET_FACILITY_DATA";

export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";
export const RESET_ADMIN_DATA = "RESET_ADMIN_DATA";

export const FETCH_LIST_REQUEST = "FETCH_LIST_REQUEST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";

export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

export const EDIT_DATA_REQUEST = "EDIT_DATA_REQUEST";
export const EDIT_DATA_SUCCESS = "EDIT_DATA_SUCCESS";
export const EDIT_DATA_FAILURE = "EDIT_DATA_FAILURE";

export const FETCH_DASHBOARD_REPORTS_REQUEST =
  "FETCH_DASHBOARD_REPORTS_REQUEST";
export const FETCH_DASHBOARD_REPORTS_SUCCESS =
  "FETCH_DASHBOARD_REPORTS_SUCCESS";
export const FETCH_DASHBOARD_REPORTS_FAILURE =
  "FETCH_DASHBOARD_REPORTS_FAILURE";

export const POST_DASHBOARD_REPORTS_REQUEST = "POST_DASHBOARD_REPORTS_REQUEST";
export const POST_DASHBOARD_REPORTS_SUCCESS = "POST_DASHBOARD_REPORTS_SUCCESS";
export const POST_DASHBOARD_REPORTS_FAILURE = "POST_DASHBOARD_REPORTS_FAILURE";

export const EDIT_DASHBOARD_REPORTS_REQUEST = "EDIT_DASHBOARD_REPORTS_REQUEST";
export const EDIT_DASHBOARD_REPORTS_SUCCESS = "EDIT_DASHBOARD_REPORTS_SUCCESS";
export const EDIT_DASHBOARD_REPORTS_FAILURE = "EDIT_DASHBOARD_REPORTS_FAILURE";

export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
export const SET_SELECTED_FACILITY = 'SET_SELECTED_FACILITY';
export const SET_SELECTED_CHILD_FACILITY = 'SET_SELECTED_CHILD_FACILITY';
export const SET_SELECTED_CLIENT_ID = 'SET_SELECTED_CLIENT_ID';
export const SET_SELECTED_FACILITY_ID = 'SET_SELECTED_FACILITY_ID';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS'