import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  POST_DATA_REQUEST,
  POST_DATA_SUCCESS,
  POST_DATA_FAILURE,
  EDIT_DATA_REQUEST,
  EDIT_DATA_SUCCESS,
  EDIT_DATA_FAILURE,
  RESET_FACILITY_DATA,  RESET_ADMIN_DATA,
} from "./actionTypes.js";
//getFacilitySFList
export const facilityDataRequest = () => {
  return {
    type: FETCH_DATA_REQUEST,
  };
};

export const facilityDataSuccess = (data) => {
  return {
    type: FETCH_DATA_SUCCESS,
    payload: data,
  };
};

export const facilityDataFailure = (error) => {
  return {
    type: FETCH_DATA_FAILURE,
    payload: error,
  };
};
export const resetFacilityData = ()=>{
  return{
    type:RESET_FACILITY_DATA,
  }
}
//getFacilityDBList
export const fetchDbListRequest = () => {
  return {
    type: FETCH_LIST_REQUEST,
  };
};

export const fetchDBListSuccess = (data) => {
  return {
    type: FETCH_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchDBListFailure = (error) => {
  return {
    type: FETCH_LIST_FAILURE,
    payload: error,
  };
};

// Action creators
export const fetchAdminsRequest = () => ({
  type: FETCH_ADMINS_REQUEST,
});

export const fetchAdminsSuccess = (data) => ({
  type: FETCH_ADMINS_SUCCESS,
  payload: data,
});

export const fetchAdminsFailure = (error) => ({
  type: FETCH_ADMINS_FAILURE,
  payload: error,
});
 
export const resetAdminData = ()=>{
  return{
    type:RESET_ADMIN_DATA,
  }
}
 

// Post Action creators
export const postDataRequest = () => ({
    type: POST_DATA_REQUEST,
  });
  
  export const postDataSuccess = (data) => ({
    type: POST_DATA_SUCCESS,
    payload: data,
  });
  
  export const postDataFailure  = (error) => ({
    type: POST_DATA_FAILURE,
    payload: error,
  });

  // edit Action creators
export const editDataRequest = () => ({
  type: EDIT_DATA_REQUEST,
});
 
export const editDataSuccess = (data) => ({
  type: EDIT_DATA_SUCCESS,
  payload: data,
});
 
export const editDataFailure  = (error) => ({
  type: EDIT_DATA_FAILURE,
  payload: error,
});
 
 