import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store";
import FacilityPageTable from "../pages/SuperAdmin/Facility/FacilityPageTable";
import WorkSiteList from "../pages/SuperAdmin/Facility/WorkSitesList";
import PrivateRoute from "../components/Commons/PrivateRoute";
import { ProgressSpinner } from "primereact/progressspinner";
import CandidateEditReport from "../pages/SuperAdmin/Dashboard/Candidate/CandidateEditReport";
import IframePage from "../components/Commons/IframePage";
import SchedulingEditReport from "../pages/SuperAdmin/Dashboard/Scheduling/SchedulingEditReport";
import SpendManagementEditReport from "../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagementEditReport";
import LocumsAnalyticsEditReport from "../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalyticsEditReport";
import LandingPageDashboardEditReport from "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboardEditReport";
import ScheduledReportsEditReport from "../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReportsEditReport";

const Login = lazy(() => import("../pages/SignIn/Signin"));
const TokenVerify = lazy(() => import("../pages/SignIn/TokenVerify"));
const SuperAdminPage = lazy(() => import("../pages/SuperAdmin/SuperAdminPage"));
const Facility = lazy(() => import("../pages/SuperAdmin/Facility"));
const DashboardPage = lazy(() => import("../pages/SuperAdmin/DashboardPage"));
const Candidate = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/Candidate")
);
const Scheduling = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Scheduling/Scheduling")
);
const SpendManagementDashboard = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagement")
);
const ScheduledReports = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReports")
);
const LandingPageDashboard = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboard"
  )
);
const LocumsAnalytics = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalytics")
);
const EmailVerificationSuccess = lazy(() =>
  import("../pages/SignIn/EmailVerificationSuccess")
);
const CandidateMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/CandidateMain")
);
const CandidateAddReport = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/CandidateAddReport")
);
const SchedulingMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Scheduling/SchedulingMain")
);
const SchedulingAddReport = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Scheduling/SchedulingAddReport")
);
const SpendManagementMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagementMain")
);
const SpendManagementAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagementAddReport"
  )
);
const LocumsAnalyticsMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalyticsMain")
);
const LocumsAnalyticsAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalyticsAddReport"
  )
);
const LandingPageDashboardMain = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboardMain"
  )
);
const LandingPageDashboardAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboardAddReport"
  )
);
const ScheduledReportsMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReportsMain")
);
const ScheduledReportsAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReportsAddReport"
  )
);
const ScheduleManagement = lazy(() =>
  import("../pages/SuperAdmin/ScheduleManagement")
);
const SchedulingConfirmations = lazy(() =>
  import("../pages/SuperAdmin/ScheduleManagement/SchedulingConfirmations")
);
const SchedulingCalenders = lazy(() =>
  import("../pages/SuperAdmin/ScheduleManagement/SchedulingCalenders")
);
const SpendMtgTimesheets = lazy(() =>
  import("../pages/SuperAdmin/SpendManagement/SpendMtgTimesheets")
);
const SpendMtgInvoices = lazy(() =>
  import("../pages/SuperAdmin/SpendManagement/SpendMtgInvoices")
);
const SpendManagement = lazy(() =>
  import("../pages/SuperAdmin/SpendManagement")
);
const TableauDasboard = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/TableauDasboard")
);

const NotFound = lazy(() => import("../pages/NotFound"));
const RoutesConfig = () => {
  return (
    <Provider store={store}>
      <Router>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {/* Use ProgressSpinner as the loader */}
              <ProgressSpinner />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/token" element={<TokenVerify />} />
            <Route
              path="/email-verification-success"
              element={<EmailVerificationSuccess />}
            />
            <Route
              path="/super-admin/*"
              element={
                <PrivateRoute>
                  <SuperAdminPage />
                </PrivateRoute>
              }
            >
              <Route
                path="*"
                element={
                  <PrivateRoute>
                    <Facility />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <FacilityPageTable />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="worksite-list"
                  element={
                    <PrivateRoute>
                      <WorkSiteList />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="dashboard/*"
                element={
                  <PrivateRoute>
                    <DashboardPage />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <Candidate />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <CandidateMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute>
                        <CandidateAddReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute>
                        <CandidateEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <IframePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="tableauDasboard"
                    element={
                      <PrivateRoute>
                        <TableauDasboard />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="scheduling"
                  element={
                    <PrivateRoute>
                      <Scheduling />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <SchedulingMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute>
                        <SchedulingEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <IframePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute>
                        <SchedulingAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="spend-management"
                  element={
                    <PrivateRoute>
                      <SpendManagementDashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <SpendManagementMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute>
                        <SpendManagementEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <IframePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute>
                        <SpendManagementAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="locums-analytics"
                  element={
                    <PrivateRoute>
                      <LocumsAnalytics />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <LocumsAnalyticsMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute>
                        <LocumsAnalyticsEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <IframePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute>
                        <LocumsAnalyticsAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="landing-page-dashboard"
                  element={
                    <PrivateRoute>
                      <LandingPageDashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <LandingPageDashboardMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute>
                        <LandingPageDashboardEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <IframePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute>
                        <LandingPageDashboardAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="scheduled-reports"
                  element={
                    <PrivateRoute>
                      <ScheduledReports />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <ScheduledReportsMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute>
                        <ScheduledReportsEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <IframePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute>
                        <ScheduledReportsAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="schedule-management/*"
                element={
                  <PrivateRoute>
                    <ScheduleManagement />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <SchedulingConfirmations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="scheduling-calenders"
                  element={
                    <PrivateRoute>
                      <SchedulingCalenders />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="spend-management/*"
                element={
                  <PrivateRoute>
                    <SpendManagement />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <SpendMtgTimesheets />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="spend-mtg-invoices"
                  element={
                    <PrivateRoute>
                      <SpendMtgInvoices />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Route>

            {/* Secure Routes */}
            {/* <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} /> */}

            {/* Fallback for non-existing routes */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default RoutesConfig;
