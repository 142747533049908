import axios from "axios";
import { headersRequest } from "../../helpers/commonUtills";
import {
  facilityDataRequest,
  facilityDataSuccess,
  facilityDataFailure,
  fetchDbListRequest,
  fetchDBListSuccess,
  fetchDBListFailure,
  fetchAdminsRequest,
  fetchAdminsSuccess,
  fetchAdminsFailure,
  postDataRequest,
  postDataSuccess,
  postDataFailure,
  editDataRequest,
  editDataSuccess,
  editDataFailure,
} from "./listActions.js";
import constants from "../../helpers/en.js";
import {
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
} from "./actionTypes.js";

export const getFacilitySFList = (facilityPrompt = "") => {
  return (dispatch) => {
    dispatch(facilityDataRequest());

    // Construct the URL dynamically based on the search query
    const url = `${process.env.REACT_APP_API_URL}/facilities?facilityPrompt=${facilityPrompt}&limit=20`;
    axios
      .get(url, {
        headers: headersRequest,
      })
      .then((response) => {
        dispatch(facilityDataSuccess(response.data));
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch(facilityDataFailure(error.message));
      });
  };
};

export const getFacilityDBList = (facilityPrompt = "") => {
  return (dispatch) => {
    dispatch(fetchDbListRequest());
    // Construct the URL dynamically based on the search query
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/facilityDetails`;
    axios
      .get(url, {
        headers: headersRequest,
      })
      .then((response) => {
        dispatch(fetchDBListSuccess(response.data));
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch(fetchDBListFailure(error.message));
      });
  };
};

// Thunk action for fetching admins getAdminSFList
export const getAdminSFList = (facilityId) => async (dispatch) => {
  dispatch(fetchAdminsRequest());
  const url = `${process.env.REACT_APP_API_URL}/users?facilityId=${facilityId}&offset=0&limit=20`;

  try {
    const response = await axios.get(url, {
      headers: headersRequest,
    });
    dispatch(fetchAdminsSuccess(response.data));
  } catch (error) {
    dispatch(
      fetchAdminsFailure(error.response?.data || "Something went wrong")
    );
  }
};

// Async action  postTwoRequests
export const addFacility =
  (facilityPayload, adminPayload, toast, closePopup) => async (dispatch) => {
    dispatch(postDataRequest());
    try {
      // First API call
      const facilityResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/facilityDetails`,
        facilityPayload,
        { headers: headersRequest }
      );

      if (facilityResponse?.status === 200) {
        const adminResponse = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_URL}/userDetails`,
          adminPayload,
          { headers: headersRequest }
        );

        if (adminResponse?.status === 200) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: constants.FACILITY_SUCCESSFULLY_MESSAGE,
            life: 3000,
          });

          dispatch(postDataSuccess(adminResponse.data));
          dispatch(getFacilityDBList());
          setTimeout(() => {
            closePopup();
          }, 1000);
        } else {
          throw new Error("Admin creation failed");
        }
      } else {
        throw new Error("Facility creation failed");
      }
    } catch (error) {
      // Handle errors with unique codes
      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 400:
            toast.current?.show({
              severity: "warn",
              summary: "Validation Error",
              detail: data.message || "Invalid request data",
              life: 3000,
            });
            break;
          case 401:
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              detail: constants.ERROR_401_MESSAGE,
              life: 3000,
            });
            break;
          case 403:
            toast.current?.show({
              severity: "error",
              summary: "Forbidden",
              detail: constants.ERROR_403_MESSAGE,
              life: 3000,
            });
            break;
          case 404:
            toast.current?.show({
              severity: "error",
              summary: "Not Found",
              detail: constants.ERROR_404_MESSAGE,
              life: 3000,
            });
            break;
          case 500:
            toast.current?.show({
              severity: "error",
              summary: "Service Unavailable",
              detail: constants.ERROR_500_MESSAGE,
              life: 3000,
            });
            break;
          case 503:
            toast.current?.show({
              severity: "error",
              summary: "Service Unavailable",
              detail: constants.ERROR_503_MESSAGE,
              life: 3000,
            });
            break;
          default:
            toast.current?.show({
              severity: "error",
              summary: "Error",
              detail: data?.message || "An unexpected error occurred.",
              life: 3000,
            });
        }
      } else {
        // Handle network or unknown errors
        toast.current?.show({
          severity: "error",
          summary: "Network Error",
          detail: error.message || "Unable to connect to the server.",
          life: 3000,
        });
      }

      dispatch(postDataFailure(error.message || "Error occurred"));
    }
  };

/// Async action  editRequests
export const editFacility =
  (adminPayload, toast, closePopup) => async (dispatch) => {
    dispatch(editDataRequest());
    try {
      const adminResponse = await axios.patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/userDetails`,
        adminPayload,
        { headers: headersRequest }
      );

      if (adminResponse?.status === 200) {
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: constants.UPDATE_FACILITY_SUCCESSFULLY_MESSAGE,
            life: 3000,
          });
        }

        dispatch(editDataSuccess(adminResponse.data));
        dispatch(getFacilityDBList());
        setTimeout(() => {
          closePopup();
        }, 1000);
      } else {
        throw new Error("Request failed");
      }
    } catch (error) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "ERROR: duplicate key value violates unique constraint",
          life: 3000,
        });
      }
      dispatch(editDataFailure(error.message));
    }
  };

//worksite api
export const getSFWorksiteList = (
  worksitePrompt = "",
  accountNumber = "",
  offset = 0,
  limit = 1000
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DATA_REQUEST });

    const url = `${process.env.REACT_APP_API_URL}/worksite?worksitePrompt=${worksitePrompt}&accountNumber=${accountNumber}&offset=${offset}&limit=${limit}`;

    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: FETCH_DATA_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch({ type: FETCH_DATA_FAILURE, payload: error.message });
      });
  };
};
