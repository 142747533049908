import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css'; 
import "./../../assets/css/CustomTooltip.scss";

import React from "react";

function CustomTooltip({
  content,
  placement = "top",
  children,
  animation = "scale",
  theme = "custom",
  arrow = true,
  delay = [150, 0],
}) {
  return (
    <Tippy
      content={content}
      placement={placement}
      animation={animation}
      theme={theme}
      arrow={arrow}
      delay={delay}
    >
      {children}
    </Tippy>
  );
}

export default CustomTooltip;
