import React, { useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/AddNewUser.scss";
import RegularButton from "../../../components/Commons/RegularButton";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Field, Form, Formik } from "formik";
import { worksiteUsers } from "../../../helpers/enums";

function AddNewUser(props) {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);

  const handleHospitalChange = (selectedOption, setFieldValue) => {
    setSelectedUser(selectedOption);
    if (selectedOption) {
      setFieldValue("userName", selectedOption.userName);
      setFieldValue("userType", selectedOption.userType);
      setFieldValue("phoneNumber", selectedOption.phoneNumber);
      setFieldValue("accountName", selectedOption.accountName);
      setFieldValue("title", selectedOption.title);
      setFieldValue("email", selectedOption.email);
    } else {
      setFieldValue("userName", "");
      setFieldValue("userType", "");
      setFieldValue("phoneNumber", "");
      setFieldValue("accountName", "");
      setFieldValue("title", "");
      setFieldValue("email", "");
    }
  };

  const successNavigate = () => {
    navigate("email-verification-success");
  };
  return (
    <div className=" modal-overlay d-flex ">
      <div className="d-flex w-100 justify-content-center ">
        <div className="modal-container">
          <div className="d-flex justify-content-between mx-3 align-items-center">
            <h2 className="popup-head-text">ADD WORKSITE USER</h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={props.closeAddNewUser}
              />
            </div>
          </div>

          <div className="rounded-3 form-container p-3">
            <div className="row g-0 px-2 px-md-5">
              <div className="col-12 col-md-6 ">
                {" "}
                <div className="row mb-2 ">
                  <label
                    className="col-4 bold primary-color"
                    htmlFor="facility-name"
                    name="facility-name"
                  >
                    Worksite
                  </label>
                  <input
                    className="form-control w-50  mb-1 col-8"
                    name="facility-name"
                    value={props.hospital.hospitalName}
                    disabled
                    id="facility-name"
                  />
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                userName: "",
                userType: "",
                phoneNumber: "",
                accountName: "",
                title: "",
                email: "",
              }}
              onSubmit={(values) => {
              }}
            >
              {({ values, setFieldValue, resetForm }) => (
                <Form>
                  <div className="row g-0 px-2 px-md-5">
                    <div className="col-12 col-md-6 ">
                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="facility-type"
                          name="facility-type"
                        >
                          Select User
                        </label>
                        <div className="w-50 mb-1 col-8 worksite-dropdown">
                          <Dropdown
                            className="w-100"
                            id="hospital"
                            value={selectedUser}
                            options={worksiteUsers}
                            onChange={(e) =>
                              handleHospitalChange(e.value, setFieldValue)
                            }
                            optionLabel="userName"
                            placeholder="Select a hospital"
                            filter
                            filterBy="label"
                            showClear
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="userType"
                          name="userType"
                        >
                          Select User Type
                        </label>
                        <Field
                          className="form-control w-50  mb-1 col-8"
                          name="userType"
                          placeholder=""
                          id="userType"
                          readOnly
                        />
                      </div>
                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="phoneNumber"
                          name="phoneNumber"
                        >
                          Phone
                        </label>
                        <Field
                          className="form-control w-50  mb-1 col-8"
                          name="phoneNumber"
                          placeholder="(123)456-7890"
                          id="phoneNumber"
                          readOnly
                        />
                      </div>
                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="accountName"
                          name="accountName"
                        >
                          Account Name
                        </label>
                        <Field
                          className="form-control w-50  mb-1 col-8"
                          name="accountName"
                          placeholder="example@exe.com"
                          id="accountName"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="add-new-user"
                          name="add-new-user"
                        >
                          Add New User
                        </label>
                        <input
                          className="form-control w-50  mb-1 col-8"
                          name="add-new-user"
                          placeholder="Add New User"
                          id="add-new-user"
                          readOnly
                        />
                      </div>

                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="title"
                          name="title"
                        >
                          Title
                        </label>
                        <Field
                          className="form-control w-50  mb-1 col-8"
                          placeholder="(123)456-7890"
                          name="title"
                          id="title"
                          readOnly
                        />
                      </div>
                      <div className="row mb-2">
                        <label
                          className="col-4 star-after"
                          htmlFor="email"
                          name="email"
                        >
                          Email
                        </label>
                        <Field
                          className="form-control w-50  mb-1 col-8"
                          placeholder="Billings Clinics"
                          name="email"
                          id="email"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex p-2 justify-content-end">
                    <RegularButton
                      className="me-2"
                      color={"#ffffff"}
                      backgroundColor={"#8e94a1"}
                      btnname={"Reset"}
                      onClick={() => resetForm()}
                    />
                    <RegularButton
                      color={"#424c63"}
                      backgroundColor={"#00e676"}
                      type={"submit"}
                      onClick={successNavigate}
                      btnname={"Submit"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewUser;
