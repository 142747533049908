import React, { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/FacilityInfoPopup.scss";
import constants from "../../../helpers/en";

function FacilityInfoPopup(props) {
  const isSubscribed = props.hospital?.tableau_subscription_ud;

  return (
    <div className="modal-overlay-facilityInfo">
      <div className="d-flex w-100 justify-content-center">
        <div className="modal-container-facilityInfo">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="popup-head-text">
              {props.hospital?.account_name_fd.toUpperCase()}
            </h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={props.closeFacilityInfo}
              />
            </div>
          </div>

          <p className="main-text bold">{constants.FACILITY_INFORMATION}</p>
          <div className="row g-0">
            <div className="col-12 col-md-6">
              <div className="row">
                <p className="col-4 star-after bold">
                  {constants.ACCOUNT_NAME}
                </p>
                <p className="w-52 mb-4 col-8">
                  {props.hospital?.account_name_fd
                    ? props.hospital?.account_name_fd
                    : "--"}
                </p>
              </div>
              <div className="row">
                <p className="col-4 ">{constants.ACCOUNT_STATUS}</p>
                <p className="w-50 mb-4 col-8">
                  {props.hospital?.account_status_fd
                    ? props.hospital?.account_status_fd
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4">{constants.ADDRESS}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.street_fd ? props.hospital?.street_fd : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4"></p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.city_fd ? props.hospital?.city_fd : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4"></p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.state_fd ? props.hospital?.state_fd : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4"> </p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.zip_code_fd
                    ? props.hospital?.zip_code_fd
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4 "></p>
                <p className="w-50 mb-4 col-8">
                  {props.hospital?.country_fd
                    ? props.hospital?.country_fd
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4 ">{constants.DEFINITIVE_ID}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.definitive_id_fd
                    ? props.hospital?.definitive_id_fd
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                <p className="col-4">{constants.ACCOUNT_NUMBER}</p>
                <p className="w-50 mb-4 col-8">
                  {props.hospital?.facility_account_no_fd
                    ? props.hospital?.facility_account_no_fd
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4 ">{constants.WEBSITE}</p>
                <p className="w-50 mb-5 col-8">
                  {props.hospital?.website_fd
                    ? props.hospital?.website_fd
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4">{constants.TYPE}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.specialities_fd
                    ? props.hospital?.specialities_fd
                    : "-"}
                </p>
              </div>
            </div>
          </div>

          <hr className="dotted-line-facilityInfo mx-5" />

          {/* Tableau Subscription Toggle with TRUE/FALSE inside the button */}
          <div className="d-flex align-items-center">
            <label
              htmlFor="custom-subscription-switch"
              className="bold"
              style={{ marginRight: "1rem" }}
            >
              {constants.TABLEAU_SUBSCRIPTION}
            </label>

            <div
              className={`custom-switch ${isSubscribed ? "active" : ""}`}
              style={{ marginLeft: "4%" }}
            >
              <div className="custom-toggle"></div>
            </div>
            <span className="ms-2">{isSubscribed ? "TRUE" : "FALSE"}</span>
          </div>

          <p className="main-text bold">{constants.FACILITY_ADMIN}</p>
          <div className="row g-0">
            <div className="col-12 col-md-6">
              <div className="row px-2 px-md-0">
                <p className="col-4 star-after bold">
                  {constants.SELECT_ADMIN}
                </p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.full_name_ud
                    ? props.hospital?.full_name_ud
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">
                <p className="col-4">{constants.FULL_NAME}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.full_name_ud
                    ? props.hospital?.full_name_ud
                    : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4">{constants.TITLE}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.role_ud ? props.hospital?.role_ud : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4">{constants.PHONE_NUMBER}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.phone_ud ? props.hospital?.phone_ud : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4">{constants.EMAIL}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.email_ud ? props.hospital?.email_ud : "-"}
                </p>
              </div>
              <div className="row">
                <p className="col-4">{constants.ACCOUNT_NAME}</p>
                <p className="w-50 mb-1 col-8">
                  {props.hospital?.account_name_ud
                    ? props.hospital?.account_name_ud
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacilityInfoPopup;
