import axios from "axios";
import {
  dashboardReportListFailure,
  dashboardReportListRequest,
  dashboardReportListSuccess,
  editDashboardReportFailure,
  editDashboardReportRequest,
  postDashboardReportFailure,
  postDashboardReportRequest,
} from "./dashboardListActions";
import constants from "../../helpers/en.js";
import { headersRequest } from "../../helpers/commonUtills";

//GET DASHBOARD REPORTS
export const getDashboardReportsList = (
  pageType,
  selectedClientId,
  selectedFacilityId = "",
  toastRef
) => {
  return (dispatch) => {
    if (
      selectedClientId !== null &&
      selectedClientId !== "" &&
      selectedClientId !== undefined
    ) {
      dispatch(dashboardReportListRequest());
      if (
        selectedFacilityId !== null &&
        selectedFacilityId !== "" &&
        selectedFacilityId !== undefined
      ) {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=${pageType}&facility_account_no=${selectedClientId}&worksite_account_no=${selectedFacilityId}`,
            { headers: headersRequest }
          )
          .then((response) => {
            dispatch(dashboardReportListSuccess(response.data));
            if (response.data === "No records found") {
              toastRef.current?.showInfo(constants.NEW_RECORDS_FOUND);
            }
          })
          .catch((error) => {
            console.error("API Error:", error.message);
            dispatch(dashboardReportListFailure(error.message));
          });
      } else {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=${pageType}&facility_account_no=${selectedClientId}`,
            { headers: headersRequest }
          )
          .then((response) => {
            dispatch(dashboardReportListSuccess(response.data));
            if (response.data === "No records found") {
              toastRef.current?.showInfo(constants.NEW_RECORDS_FOUND);
            }
          })
          .catch((error) => {
            console.error("API Error:", error.message);
            dispatch(dashboardReportListFailure(error.message));
          });
      }
    }
  };
};

//ADD DASHBOARD REPORTS
export const addDashboardReports =
  (dashboardReportPayload, toastRef, navigateBack) => async (dispatch) => {
    dispatch(postDashboardReportRequest());
    try {
      const dashboardResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/dashboard`,
        dashboardReportPayload,
        { headers: headersRequest }
      );

      if (dashboardResponse?.status === 201) {
        toastRef.current?.showSuccess(constants.REPORT_SUBMITTED_SUCCESSFULLY);
        setTimeout(() => {
          navigateBack();
        }, 1000);
      } else {
        toastRef.current?.showError(dashboardResponse?.status);

        throw new Error(constants.REPORT_SUBMISSION_FAILED);
      }
    } catch (error) {
      if (
        error.response.data?.description[0] ===
        "/0/facility_account_no expected type: Number, found: Null"
      ) {
        toastRef.current?.showError(constants.CLIET_FIELD_IS_REQUIRED);
      } else {
        toastRef.current?.showError(error.response.data?.description[0]);
      }
      // Handle errors with unique codes

      dispatch(postDashboardReportFailure(error.message || "Error occurred"));
    }
  };

//EDIT DASHBOARD REPORTS
export const editDashboardReports =
  (dashboardReportPayload, toastRef, navigateBack) => async (dispatch) => {
    dispatch(editDashboardReportRequest());
    try {
      const dashboardResponse = await axios.patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/dashboard`,
        dashboardReportPayload,
        { headers: headersRequest }
      );

      if (dashboardResponse?.status === 200) {
        toastRef.current?.showSuccess(constants.REPORT_UPDATED_SUCCESSFULLY);
        setTimeout(() => {
          navigateBack();
        }, 2000);
      } else {
        toastRef.current?.showError(dashboardResponse?.status);
        throw new Error(constants.REPORT_UPDATION_FAILED);
      }
    } catch (error) {
      // Handle errors with unique codes
      if (error.response) {
        toastRef.current?.showError("error");
      }
      dispatch(editDashboardReportFailure(error.message || "Error occurred"));
    }
  };
