import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import AddButton from "../../../components/Commons/AddButton";
import "../../../assets/css/FacilityPageTable.scss";
import { ReactComponent as Gotoo } from "../../../assets/images/icons/Gotoo.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as Delete } from "../../../assets/images/icons/delete.svg";
import { ReactComponent as AddUser } from "../../../assets/images/icons/AddUser.svg";
import { ReactComponent as ViewWorkSite } from "../../../assets/images/icons/ViewWorkSite.svg";
import AddFaciltyPopup from "./AddFaciltyPopup";
import FacilityInfoPopup from "./FacilityInfoPopup";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { useNavigate } from "react-router";
import AddNewUser from "./AddNewUser";
import { useDispatch, useSelector } from "react-redux";
import { getFacilityDBList } from "../../../redux/actions/facilityActions";
import EditFacilityPopup from "./EditFacilityPopup";
import { indexTemplate } from "../../../utils";
import constants from "../../../helpers/en";
import Worksite from "../../SuperAdmin/Facility/WorkSitesList";

function FacilityPageTable() {
  const [openAddFacilityPopup, setAddFacilityPopup] = useState(false);
  const [openFacilityInfoPopup, setFacilityInfoPopup] = useState(false);
  const [openAddUserPopup, setAddUserPopup] = useState(false);
  const [openEditFacilityPopup, setEditFacility] = useState(false);
  const [selectedFacilityAccountNo, setSelectedFacilityAccountNo] =
    useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.facilityList);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);

  const onPage = (event) => {
    setFirst(event.first);
  };

  useEffect(() => {
    setSortField(null);
    setSortOrder(null);
  }, [data]);

  useEffect(() => {
    dispatch(getFacilityDBList());
  }, [dispatch]);
  // Updated filter fields to exactly match data structure of facilitiData

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      account_name_fd: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facility_account_no_fd: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      full_name_ud: { value: null, matchMode: FilterMatchMode.CONTAINS },
      account_status_fd: { value: null, matchMode: FilterMatchMode.CONTAINS },
      create_date_fd: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }));
  }, [data]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    account_name_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    facility_account_no_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    full_name_ud: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    account_status_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    create_date_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });

  const openForm = () => {
    setAddFacilityPopup(true);
  };
  const closeForm = () => {
    setAddFacilityPopup(false);
  };
  const openFacilityInfo = () => {
    setFacilityInfoPopup(true);
  };
  const closeFacilityInfo = () => {
    setFacilityInfoPopup(false);
  };
  const openAddNewUser = () => {
    setAddUserPopup(true);
  };
  const closeAddNewUser = () => {
    setAddUserPopup(false);
  };
  const openEditFacility = () => {
    setEditFacility(true);
  };
  const closeEditFacility = () => {
    setEditFacility(false);
  };

  const statusBodyTemplate = (rowData) => {
    let classnames = "";
    let statusText = rowData.account_status_fd ? "Active" : "Inactive";

    switch (statusText) {
      case "Active":
        classnames = "active-status";
        break;
      case "Inactive":
        classnames = "onhold-status";
        break;
      default:
        classnames = "active-status";
    }
    return <span className={classnames}>{statusText}</span>;
  };

  const [selectedHospital, setSelectedHospital] = useState(null);
  const showFacilityInfoPopup = (hospital) => {
    setSelectedHospital(hospital);
    openFacilityInfo();
  };

  const showAddNewUserPopup = (hospital) => {
    setSelectedHospital(hospital);
    openAddNewUser();
  };
  const showEditFacilityPopup = (hospital) => {
    setSelectedHospital(hospital);
    openEditFacility();
  };
  // const dateFormatConvert = (rowData) => {
  //   const dateParts = rowData?.create_date_fd?.split("T");
  //   const date = dateParts[0];
  //   return <span>{date}</span>;
  // };
  const dateFormatConvert = (rowData) => {
    const date = new Date(rowData?.create_date_fd);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return (
      <span>
        {month}/{day}/{year}
      </span>
    );
  };

  const navigateToWorksite = (rowData) => {
    const accountNo = rowData?.facility_account_no_fd;
    const parentName = rowData.account_name_fd;
    setSelectedFacilityAccountNo(accountNo, parentName);
    navigate("worksite-list", { state: { accountNo, parentName } });
  };

  const AddBodyTemplate = (rowData) => (
    <div className="action-buttons-container">
      <CustomTooltip content="View Work Site">
        <ViewWorkSite
          className="action-icons"
          onClick={() => navigateToWorksite(rowData)}
        />
      </CustomTooltip>
      <CustomTooltip content="Add User">
        <AddUser
          className="action-icons"
          onClick={() => showAddNewUserPopup(rowData)}
        />
      </CustomTooltip>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <CustomTooltip content={"Edit"}>
          <Edit
            className="action-icons"
            onClick={() => showEditFacilityPopup(rowData)}
          />
        </CustomTooltip>
        <CustomTooltip content={"Delete"}>
          <Delete
            className="action-icons"
            data-pr-tooltip="Delete"
            onClick={() => {
              alert("Delete");
            }}
          />
        </CustomTooltip>
        <CustomTooltip content={"More Info"}>
          <Gotoo
            className="action-icons"
            onClick={() => showFacilityInfoPopup(rowData)}
          />
        </CustomTooltip>
      </div>
    );
  };

  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      let _filters = { ...filters };
      _filters["global"] = {
        value: value,
        matchMode: FilterMatchMode.CONTAINS,
      };
      setFilters(_filters);
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";
    return (
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          value={value || ""}
          onChange={(event) => onGlobalFilterChange(event)}
          placeholder="Global Search"
        />
      </IconField>
    );
  };
  const header = renderHeader();

  // const rowClassName = (rowData) => {
  //   const index = data.indexOf(rowData);
  //   return index % 2 === 0 ? "even-row" : "odd-row";
  // };

  return (
    <div className="px-4 py-3 flex-grow-1 overflow-auto dashboard-table ">
      <div className="d-flex justify-content-between facility-head-div">
        <h2 className="page-heading">{constants.FACILITY_DETAILS}</h2>
        <div>
          <AddButton name={constants.ADD_FACILITY_BUTTON} onClick={openForm} />
        </div>
      </div>
      <DataTable
        className="table-responsive"
        value={loading ? [] : data}
        loading={loading}
        stripedRows
        header={header}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={10}
        first={first}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={(e) => {
          setSortField(e.sortField);
          setSortOrder(e.sortOrder);
        }}
        onPage={onPage}
        onFilter={(e) => setFilters(e.filters)}
        dataKey="facililty_id_fd"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-demo-local"
        emptyMessage="No facility found."
      >
        <Column
          className="f_no_column"
          body={indexTemplate}
          header="F.No."
          headerClassName="f_no_column_header"
        ></Column>
        <Column
          className="account_name_column"
          header="Account Name"
          field="account_name_fd"
          body={(rowData) => (
            <span className="ellipsis-text">{rowData.account_name_fd}</span>
          )}
          sortable
          filter
          filterField="account_name_fd"
          filterPlaceholder="Search"
        ></Column>
        <Column
          className="account_number_column"
          header="Account Number"
          field="facility_account_no_fd"
          body={(rowData) => (
            <span className="ellipsis-text-accuntNo">
              {rowData.facility_account_no_fd}
            </span>
          )}
          sortable
          filter
          filterField="facility_account_no_fd"
          filterPlaceholder="Search"
        ></Column>
        <Column
          className="facility_admin_column"
          header="Facility Admin"
          field="full_name_ud"
          body={(rowData) => (
            <span className="ellipsis-text-admin">{rowData.full_name_ud}</span>
          )}
          sortable
          filter
          filterField="full_name_ud"
          filterPlaceholder="Search"
        ></Column>
        <Column
          className="status_column"
          field="account_status_fd"
          header="Account Status"
          body={(rowData) => (
            <span className="ellipsis-text-status">
              {rowData.account_status_fd}
            </span>
          )}
          // body={statusBodyTemplate}
          sortable
          filter
          filterField="account_status_fd"
          filterPlaceholder="Search"
        ></Column>
        <Column
          className="create_date_column"
          field="create_date_fd"
          // body={dateFormatConvert}
          header="Create Date"
          body={(rowData) => (
            <span className="ellipsis-text-date">{rowData.create_date_fd}</span>
          )}
          sortable
          filter
          filterField="create_date_fd"
          filterPlaceholder="Search"
        ></Column>
        <Column
          className="worksite_column"
          header="Work Site"
          body={AddBodyTemplate}
        ></Column>
        <Column
          className="action_column"
          header="Action"
          body={actionBodyTemplate}
        ></Column>
      </DataTable>

      {openAddFacilityPopup && <AddFaciltyPopup closeForm={closeForm} />}
      {openFacilityInfoPopup && (
        <FacilityInfoPopup
          hospital={selectedHospital}
          closeFacilityInfo={closeFacilityInfo}
        />
      )}
      {openAddUserPopup && (
        <AddNewUser
          hospital={selectedHospital}
          closeAddNewUser={closeAddNewUser}
        />
      )}
      {openEditFacilityPopup && (
        <EditFacilityPopup
          hospital={selectedHospital}
          closeEditFacility={closeEditFacility}
        />
      )}
      {selectedFacilityAccountNo && (
        <Worksite facilityAccountNum={selectedFacilityAccountNo} />
      )}
    </div>
  );
}

export default FacilityPageTable;
