import {
  CLEAR_SELECTIONS,
  EDIT_DASHBOARD_REPORTS_FAILURE,
  EDIT_DASHBOARD_REPORTS_REQUEST,
  EDIT_DASHBOARD_REPORTS_SUCCESS,
  FETCH_DASHBOARD_REPORTS_FAILURE,
  FETCH_DASHBOARD_REPORTS_REQUEST,
  FETCH_DASHBOARD_REPORTS_SUCCESS,
  POST_DASHBOARD_REPORTS_FAILURE,
  POST_DASHBOARD_REPORTS_REQUEST,
  POST_DASHBOARD_REPORTS_SUCCESS,
  SET_SELECTED_CHILD_FACILITY,
  SET_SELECTED_CLIENT,
  SET_SELECTED_CLIENT_ID,
  SET_SELECTED_FACILITY,
  SET_SELECTED_FACILITY_ID,
} from "./actionTypes";

//GET DASHBOARD REPORT LIST
export const dashboardReportListRequest = () => {
  return {
    type: FETCH_DASHBOARD_REPORTS_REQUEST,
  };
};

export const dashboardReportListSuccess = (data) => {
  return {
    type: FETCH_DASHBOARD_REPORTS_SUCCESS,
    payload: data,
  };
};

export const dashboardReportListFailure = (error) => {
  return {
    type: FETCH_DASHBOARD_REPORTS_FAILURE,
    payload: error,
  };
};

//POST DASHBOARD REPORTS
export const postDashboardReportRequest = () => ({
  type: POST_DASHBOARD_REPORTS_REQUEST,
});

export const postDashboardReportSuccess = (data) => ({
  type: POST_DASHBOARD_REPORTS_SUCCESS,
  payload: data,
});

export const postDashboardReportFailure = (error) => ({
  type: POST_DASHBOARD_REPORTS_FAILURE,
  payload: error,
});

// EDIT DASHBOARD REPORTS
export const editDashboardReportRequest = () => ({
  type: EDIT_DASHBOARD_REPORTS_REQUEST,
});

export const editDashboardReportSuccess = (data) => ({
  type: EDIT_DASHBOARD_REPORTS_SUCCESS,
  payload: data,
});

export const editDashboardReportFailure = (error) => ({
  type: EDIT_DASHBOARD_REPORTS_FAILURE,
  payload: error,
});

//SET CLIENT, CLIENTID, FACILITY, FACILITYID, CHILDFACILITY
export const setSelectedClient = (selectedClient) => ({
  type: SET_SELECTED_CLIENT,
  payload: selectedClient,
});

export const setSelectedFacility = (selectedFacility) => ({
  type: SET_SELECTED_FACILITY,
  payload: selectedFacility,
});

export const setSelectedChildFacility = (selectedChildFacility) => ({
  type: SET_SELECTED_CHILD_FACILITY,
  payload: selectedChildFacility,
});

export const setSelectedClientId = (selectedClientId) => ({
  type: SET_SELECTED_CLIENT_ID,
  payload: selectedClientId,
});

export const setSelectedFacilityId = (selectedFacilityId) => ({
  type: SET_SELECTED_FACILITY_ID,
  payload: selectedFacilityId,
});

export const clearSelections = () => ({
  type: CLEAR_SELECTIONS,
});
