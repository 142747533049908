import {
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  POST_DATA_REQUEST,
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  POST_DATA_SUCCESS,
  POST_DATA_FAILURE,
  RESET_FACILITY_DATA,
  RESET_ADMIN_DATA,
} from "../actions/actionTypes";

// Initial state
const initialState = {
  loading: false,
  data: [],
  error: "",
};

// Reducer function
export const facilitySFReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_DATA_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    case RESET_FACILITY_DATA:
      return initialState;

    default:
      return state;
  }
};

// Reducer function
export const getFacilityDBReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LIST_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_LIST_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMINS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_ADMINS_SUCCESS:
      return { ...state, loading: false, admins: action.payload };
    case FETCH_ADMINS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RESET_ADMIN_DATA:
      return initialState;
    default:
      return state;
  }
};

export const AddFacilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case POST_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case POST_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//worksite
export const worksiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_DATA_SUCCESS:
      return { ...state, worksiteData: action.payload, loading: false };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        worksiteData: [],
      };
    default:
      return state;
  }
};
