
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React, { forwardRef, useRef } from "react";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";

const CustomToast = forwardRef((props, ref) => {
  const toastRef = useRef(null);

  React.useImperativeHandle(ref, () => ({
    showSuccess: (message) => {
      toastRef.current.show({
        severity: "success",
        summary: "Success",
        detail: message,
        life: 3000,
      });
    },
    showError: (message) => {
      toastRef.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
        life: props.life || 3000,
      });
    },
    showInfo: (message) => {
      toastRef.current.show({
        severity: "info",
        summary: "Info",
        detail: message,
        life: props.life || 3000,
      });
    },
    showWarn: (message) => {
      toastRef.current.show({
        severity: "warn",
        summary: "Warning",
        detail: message,
        life: props.life || 3000,
      });
    },
  }));

  return (
    <Toast
      ref={toastRef}
      position={props.position || "top-right"}
      className={props.className || ""}
    />
  );
});

CustomToast.propTypes = {
  position: PropTypes.string,
  life: PropTypes.number,
  className: PropTypes.string,
};

CustomToast.defaultProps = {
  position: "top-right",
  life: 3000,
  className: "",
};

export default CustomToast;
