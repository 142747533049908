import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  // Show a loading state while the Auth0 state is being determined
  if (isLoading) {
    return (
      <div>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* Use ProgressSpinner as the loader */}
          <ProgressSpinner />
        </div>
      </div>
    );
  }

  // If the user is not authenticated, redirect them to the login page
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // If the user is authenticated, render the children (the protected route component)
  return children;
};

export default PrivateRoute;
