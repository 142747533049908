// reducers/index.js
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import {
  facilitySFReducer,
  getFacilityDBReducer,
  AddFacilityReducer,
  adminListReducer,
  worksiteReducer,
} from "./facilityReducer";
import {
  getDashboardReportsReducer,
  selectionReducer,
} from "./dashboardReducers";

const rootReducer = combineReducers({
  UserInfo: authReducer,
  hospital: facilitySFReducer,
  admin: adminListReducer,
  facilityList: getFacilityDBReducer,
  post: AddFacilityReducer,
  worksite: worksiteReducer,
  dashboardReportList: getDashboardReportsReducer,
  selection: selectionReducer,
});

export default rootReducer;
