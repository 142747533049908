import React from "react";
import "../../assets/css/RegularButton.scss";

function RegularButton({
  color = "#fff",
  btnname = "Click Me",
  backgroundColor = "#007bff",
  className = "",
  type = "button", 
  onClick,
}) {
  const buttonStyles = {
    color: color,
    backgroundColor: backgroundColor,
  };

  const btnclasses = `regular-btn ${className}`;
  return (
    <button className={btnclasses} style={buttonStyles} onClick={onClick}>
      {btnname}
    </button>
  );
}

export default RegularButton;
