//Add constant attributes like messages
const constants = {
  FACILITY: "Facility",
  DASHBOARD: "Dashboards",
  SCHEDULE_MANAGEMENT: "Schedule Management",
  SPEND_MANAGEMENT: "Spend Management",
  MY_PROFILE: "My Profile",
  SETTING: "Settings",
  LOGOUT: "Logout",
  ADD_FACILITY: "ADD FACILITY",
  ADD_FACILITY_BUTTON: "Add Facility",
  SUCCESSFULLY: "User added successfully",
  ACTION_INVALID: "This action is invalid!",
  USERNAME: "Username",
  LOGIN_TITLE: "WELCOME TO CLIENT PORTAL",
  LOGIN_BUTTON_CONTINUE: "Login to client portal?",
  AGREE_TERMS_TEXT: "By Signing in, you agree to our",
  TERMS_SERVICE: "Terms of Service",
  TERMS_OF_POLICY: "Privacy Policy",
  BUTTON_CONTINUE: "Continue",
  AND: "and",
  EMAIL_PLACEHOLDER: "Email",
  SELECT_HOSPITAL: "Select Client",
  SELECT_WPRKSITE: "Select Facility",
  BUTTON_SUBMIT: "Submit",
  SELECT_FACILITY: "Select Facility",
  REPORTS: "REPORTS",
  CONDIDATES: "Candidates",
  SCHEDULING: "Scheduling",
  SPEND_MANAGEMENT: "Spend Management",
  LOCUMS_ANALYTICS: "Locums Analytics",
  LANDING_PAGE: "Landing Page",
  SCHEDULING_REPORTS: "Scheduled Reports",
  ADD_NEW_FACILITY: "ADD NEW FACILITY",
  BASIC_FACILITY_INFORMATION: "1.Basic Facility Information",
  FACILITY_NAME: "Facility Name",
  FACILITY_SUCCESSFULLY_MESSAGE: "Facility added successfully!",
  FACILITY_ALREADY_EXISTS: "Facility already exists",
  UPDATE_FACILITY: "EDIT FACILITY",
  UPDATE_FACILITY_SUCCESSFULLY_MESSAGE: "Facility updated successfully!",
  ACCOUNT_NAME: "Account Name",
  SELECT_ACCOUNT_NAME: "Select account name",
  FACILITY_INFORMATION: "1. Facility Information",
  ACCOUNT_STATUS: "Account Status",
  DEFINITIVE_ID: "Definitive ID",
  ACCOUNT_NUMBER: "Account Number",
  WEBSITE: "Website",
  TYPE: "Type",
  TABLEAU_SUBSCRIPTION: "Tableau Subscription",
  FACILITY_ADMIN: "2.Facility Admin",
  SELECT_ADMIN: "Select Admin",
  FULL_NAME: "Full Name",
  TITLE: "Title",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "Email",
  BUTTTON_RESET: "Reset",
  ACCOUNT_NAME_ERROR_MESSAGE: "Please select an Account Name",
  ADMIN_NAME_ERROR_MESSAGE: "Please select an Admin",
  EDIT_FACILITY: "EDIT FACILITY",
  ADDRESS: "Address",
  FACILITY_DETAILS: "FACILITY DETAILS",
  FOOTER_COPYRIGHT: "© 2024 Copyright : Hayes Locums",
  LIMIT: 10000,
  ERROR_401_MESSAGE:"You are not authorized to perform this action.",
  ERROR_403_MESSAGE:"Access to this resource is forbidden.",
  ERROR_404_MESSAGE:"The requested resource could not be found.",
  ERROR_503_MESSAGE:"The service is temporarily unavailable. Please try again later.",
  ERROR_500_MESSAGE:"The facility already exists",
  NEW_RECORDS_FOUND:"No records Found",
  REPORT_SUBMITTED_SUCCESSFULLY:"Reports Submitted Successfully",
  REPORT_SUBMISSION_FAILED:"Report Submission failed",
  CLIET_FIELD_IS_REQUIRED:"Client Field is Required !",
  REPORT_UPDATED_SUCCESSFULLY:"Report Updated Successfully",
  REPORT_UPDATION_FAILED:"Report updation failed",
};
export default constants;
