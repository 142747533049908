import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
// import { ReactComponent as DropdownArrow } from "../../../assets/images/icons/DropdownArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSFWorksiteList } from "../../../redux/actions/facilityActions";
import { getFacilityDBList } from "../../../redux/actions/facilityActions";
import { LIMIT, COLUMNS } from "../../../helpers/enums";
import constants from "../../../helpers/en";
import { SelectButton } from "primereact/selectbutton";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import "../../../assets/css/WorksiteList.scss";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Worksite() {
  const [globalFilter, setGlobalFilter] = useState("");
  const location = useLocation();
  const { accountNo, parentName } = location.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { worksiteData, loading, error } = useSelector(
    (state) => state.worksite
  );

  const worksiteSFList = (data) => {
    if (Array.isArray(data)) {
      return data.map((worksite) => ({
        key: worksite?.accountNumber,
        data: {
          parent_name: parentName,
          select_account_num: worksite?.accountNumber,
          account_name: worksite?.name,
          account_number: worksite?.accountNumber,
          account_status: worksite?.accountStatus,
          website: worksite?.website,
          address: `${worksite?.address?.city}, ${worksite?.address?.state}`,
          type: worksite?.type,
          definitive_id: worksite?.definitiveId,
        },
        children: worksite?.childWorksite
          ? worksiteSFList(worksite?.childWorksite)
          : [],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (accountNo) {
      dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
    }
  }, [dispatch, accountNo]);

  const nodes =
    worksiteData && worksiteData.length > 0 ? worksiteSFList(worksiteData) : [];

  const rowClassName = (rowData) => {
    const flattenNodes = (nodes) => {
      let result = [];
      nodes.forEach((node) => {
        result.push(node);
        if (node.children) {
          result = result.concat(flattenNodes(node.children));
        }
      });
      return result;
    };

    const allRows = flattenNodes(nodes);
    const index = allRows.indexOf(rowData);
    //   return index % 2 === 0 ? "even-row" : "odd-row";
    const isChild = rowData.parent && rowData.parent.length > 0;
    return isChild ? "child-row" : index % 2 === 0 ? "even-row" : "odd-row";
  };

  const getHeader = () => {
    return (
      <div className="page-header">
        <div>
          <h2 className="page-heading">
            <i
              className="pi pi-chevron-left px-3"
              onClick={() => navigate("dashboard")}
              style={{ cursor: "pointer", fontSize: "1.7rem" }}
            ></i>
            Worksite Information
          </h2>
        </div>
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder=" Search"
          />
        </IconField>
      </div>
    );
  };

  let header = getHeader();

  return (
    <div className="Outerbody-card">
      <div className="card">
        {loading ? (
          <div className="loading-container">
            <div className="loader">
              <ProgressSpinner />
            </div>
          </div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <>
            <TreeTable
              className="table-responsive"
              value={nodes.length > 0 ? nodes : []}
              globalFilter={globalFilter}
              rowClassName={rowClassName}
              header={header}
              tableStyle={{
                minWidth: "50rem",
                borderRadius: "10px",
                overflow: "auto",
              }}
              paginator
              rows={5}
              emptyMessage={
                <span
                  className="custom-empty-message"
                  style={{ fontSize: "18px", marginLeft: "47%" }}
                >
                  No Data Found
                </span>
              }
            >
              {/* {columns.map((col, index) => ( */}
              {Object.values(COLUMNS).map((col, index) => (
                <Column
                  key={index}
                  field={col.field}
                  header={
                    col.field === "parent_name" ? (
                      <span style={{ marginLeft: "2rem" }}>{col.header}</span>
                    ) : (
                      col.header
                    )
                  }
                  expander={col.expander}
                  sortable={col.sortable}
                  style={col.style}
                  headerClassName={col.headerClassName}
                  headerStyle={col.headerStyle}
                />
              ))}
            </TreeTable>
          </>
        )}
      </div>
    </div>
  );
}
