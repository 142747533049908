import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from '@auth0/auth0-react';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/colorConstant.scss";
import "./assets/css/common.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
    domain={process.env.REACT_APP_Auth0Doman}
    clientId={process.env.REACT_APP_Auth0ClientId}
    authorizationParams={{
    redirect_uri: process.env.REACT_APP_CALLBACK_URL
    }}
    useRefreshTokens={true} // Enable token refreshing
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
